<template>
  <div>

    <v-row>
      <v-col>anagrafiche

        <div>1. seleziona utente</div>
        <div>2. inserisci anagrafiche se utente non presente...</div>

      </v-col>
    </v-row>


    <v-row>
      <v-col>
        <v-btn color="secondary" class="ma-2" v-on:click='onClickNewMembership'>
          <template v-slot:prepend>
            <v-icon color='success'>$plus</v-icon>
          </template>
          <span>Aggiungi Tessera</span>
        </v-btn>

        <v-btn color="blue" class='ma-2' v-on:click='onClickNewTicket'>
          <template v-slot:prepend>
            <v-icon color='blue'>$plus</v-icon>
          </template>
          <span>Aggiungi Biglietto</span>
        </v-btn>

      </v-col>
    </v-row>

    <v-row v-for="(item, i) in products" :key='i'>

      <v-col>
        {{ item.name }}
        <pre>{{ item }}</pre>
      </v-col>

    </v-row>


  </div>
</template>


<script lang='ts'>
import { Api } from '@/_api';

import { TYPE, useToast } from 'vue-toastification';

const toast = useToast();

const membershipProductID = 'b338d692-1706-4f48-ad26-7e7cc54e7057';
const ticketProductID = 'e9e198d5-6c40-4fb2-a653-e645fd369dca';

export default {
  name: 'backoffice-single-order',
  data() {
    return {
      products: Array<Api.ProductDto>()
    }
  },
  methods: {
    onClickNewMembership(e) {
      //let fika: Api.IAddOrder_Admin_Request = {};
      // ah già dio cane deve contenere 4mila dati
      this.products.push(new Api.ProductDto({
        id: membershipProductID,
        name: "Tessera Proloco 2024",
        //createdAt: new Date(),
        //updatedAt: new Date()
      }));
    },
    onClickNewTicket(e) {
      this.products.push(new Api.ProductDto({
        id: ticketProductID,
        name: "Biglietto BML 2024",
        //createdAt: new Date(),
        //updatedAt: new Date()
      }));
    },
  },
  mounted() {
  },
};
</script>
