/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
//import '@mdi/font/css/materialdesignicons.css'
import '@/styles/main.scss'
import '@/styles/settings.scss' // < for custom variables

//import 'typeface-roboto/index.css';

// Composables
import { createVuetify } from 'vuetify'
//import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'

import {
  mdiAccount,
  mdiAccountGroupOutline,
  mdiChevronLeft, mdiChevronRight,
  mdiHomeCity,
  mdiAt,
  mdiEye,
  mdiEyeOff,
  mdiPhone,
  mdiLock,
  mdiViewDashboard,
  mdiHistory,
  mdiHome,
  mdiCart,
  mdiHelp,
  mdiPencil,
  mdiMagnify,
  mdiTrashCan,
  mdiCheck,
  mdiArrowRightBold,
  mdiCameraFlip,
  mdiWalletMembership,
  mdiQrcodeScan,
  mdiQrcode,
  mdiCreditCard,
  mdiCloseThick,
  mdiLogout,
  mdiDatabase,
  mdiMarker,
  mdiScanner,
  mdiBarcodeScan,
  mdiExport,
} from '@mdi/js'


// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
// https://vuetifyjs.com/en/features/theme/#javascript
// edit efaults https://vuetifyjs.com/en/features/global-configuration/
export default createVuetify({
  ssr: false,
  theme: {
    defaultTheme: 'light',
  },
  defaults: {
    global: {
      ripple: true,
    },
    VBtn: {
      variant: 'tonal',
      //elevation: 1,
    },
    VSwitch: {
      variant: 'inset',
      color: 'primary'
    },
    VTextField: {
      rounded: 7,
      color: 'primary',
      variant: 'outlined',
      //variant: 'solo-filled',
      //flat: true,
      class: 'mb-2',
    },
    VTextarea: {
      variant: 'solo-filled'
    },
    VDataTable: {
      itemsPerPage: 100,
      itemsPerPageOptions: [50, 100, 200]
    },
    VDataTableServer: {
      itemsPerPage: 100,
      itemsPerPageOptions: [50, 100, 200]
    },

  },
  icons: {
    defaultSet: 'mdi',
    aliases: {
      ...aliases,
      account: mdiAccount,
      'chevron-left': mdiChevronLeft,
      'chevron-right': mdiChevronRight,
      homeCity: mdiHomeCity,
      accountGroupOutline: mdiAccountGroupOutline,
      at: mdiAt,
      eye: mdiEye,
      'eye-off': mdiEyeOff,
      phone: mdiPhone,
      lock: mdiLock,
      'view-dashboard': mdiViewDashboard,
      history: mdiHistory,
      home: mdiHome,
      cart: mdiCart,
      help: mdiHelp,
      pencil: mdiPencil,
      magnify: mdiMagnify,
      trash: mdiTrashCan,
      check: mdiCheck,
      arrowRightBold: mdiArrowRightBold,
      cameraFlip: mdiCameraFlip,
      walletMembership: mdiWalletMembership,
      qrCodeScan: mdiQrcodeScan,
      qrCode: mdiQrcode,
      creditCard: mdiCreditCard,
      closeThick: mdiCloseThick,
      logout: mdiLogout,
      database: mdiDatabase,
      marker: mdiMarker,
      scanner: mdiScanner,
      barcodeScan: mdiBarcodeScan,
      export: mdiExport,
    },
    sets: {
      mdi,
    },
  },
})
