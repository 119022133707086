<template>
  <v-bottom-navigation v-model="currentTab" bg-color='indigo' class='noselect' grow>

    <v-btn value="home" :to='{ name: "home" }' class='nodrag'>
      <v-icon>$home</v-icon>

      <span>Home</span>
    </v-btn>

    <v-btn value="recent" :to='{ name: "buy-tickets" }' class='nodrag'>
      <v-icon>$cart</v-icon>
      <span>Acquista</span>
    </v-btn>


    <v-btn value="recent" :to='{ name: "orders" }' class='nodrag'> <!-- :to='{ name: "orders" }' -->
      <v-icon>$history</v-icon>
      <span>Ordini</span>
    </v-btn>

    <v-btn value="profile" class='nodrag' :to='{ name: "user-profile" }'>
      <v-icon>$account</v-icon>
      <span>Profilo</span>
    </v-btn>

  </v-bottom-navigation>
</template>


<script lang='ts'>

export default {
  name: 'main-footer-mobile',
  data() {
    return {
      currentTab: 'home'
    }
  },
  watch: {
    $route(to, from) {
      // Aggiorna la tab selezionata basandoti sulla route corrente
      this.currentTab = typeof to.name === 'string' ? to.name : 'home';
    }
  },
  created() {
    this.currentTab = this.$route.name === 'string' ? this.$route.name : 'home';
  },
  methods: {},
  mounted() {
  },
};

</script>
