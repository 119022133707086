import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { loading } from './loading';
import { TYPE, useToast } from 'vue-toastification';
import { useUserStore } from '@/store/identityStore';

const toast = useToast();

// ! START THE REQUEST
const onRequest = (config: AxiosRequestConfig): any => {
  //console.info(`[request >]`, config);

  loading.start();
  // TODO: finire di implementare il timer // ma che cazzo me ne frega del time?
  //config.meta = config.meta || {};
  //config.meta.requestStartedAt = new Date().getTime();

  return config;
}

// ! RESPONSE OK
const onResponse = (error: AxiosResponse): AxiosResponse => {
  console.info(`[response <]`, error);
  //const config = error.config;

  loading.done();
  //response.durationInMs = new Date().getTime() - config.meta.requestStartedAt;
  //console.log(`Successful response took ${response.durationInMs} milliseconds`);

  return error;
}

// ! REQUEST ERROR
const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[request error]`, error);
  loading.done();

  //if (error && error.meta.toastId !== undefined) {
  //  toast.dismiss(error.meta.toastId);
  //}

  const message = parseError(error); // QUESTO NON VABENE EH
  toast(message, {
    hideProgressBar: false,
    closeOnClick: false,
    draggable: true,
    timeout: 6660,
    closeButton: 'button',
    type: TYPE.ERROR
  });

  return Promise.reject(error);
}


// ! RESPONSE ERROR
const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  console.error(`[response error]`, error);
  loading.done();
  let message = parseError(error);

  // Force logout if any auth error
  if (error.response?.status == 401) {
    const identityStore = useUserStore();
    await identityStore.logout();

    if (window.location.pathname !== '/login') { // ti mazza cristo TODO: puoi metterlo nel router (?) es: nell /login { noRedirectOn401: true }
      message = "Autenticazione fallita, disconnessione in corso"
      location.href = '/home'
    }
  }

  if (typeof message === 'string') {
    toast(message, {
      hideProgressBar: false,
      closeOnClick: false,
      draggable: true,
      timeout: 6660,
      closeButton: 'button',
      type: TYPE.ERROR
    });
  } else if (Array.isArray(message)) {
    for (let i = 0; i < Math.min(message.length, 3); i++) {
      toast(message[i], {
        hideProgressBar: false,
        closeOnClick: false,
        draggable: true,
        timeout: 6660,
        closeButton: 'button',
        type: TYPE.ERROR
      });
    }
  }

  return Promise.reject(error.response?.data);
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}

// TODO: gestire errori: 1. errori di validazione 2. errori di server generici 3. errori di rete
// TODO: preparare endpoint di test per ogni tipo di errore

function getDescription(data: any): string[] {
  if (Array.isArray(data)) {
    return data.filter(item => item && item.description).map(item => item.description);
  } else if (data && typeof data.description === 'string') {
    return [data.description];
  } else if (typeof data === 'object') { // non so perché ci siano tutte queste casistiche...
    let descriptions: string[] = [];
    for (const key of Object.keys(data)) {
      const value = data[key];
      if (Array.isArray(value) && value.every(item => typeof item === 'string')) {
        descriptions = descriptions.concat(value);
      }
    }

    return descriptions;
  }

  console.warn('missing data value', data);
  return [];
}


const parseError = (error: Error | AxiosError): string | string[] => {
  if (axios.isAxiosError(error)) {

    if (error.code === 'ERR_BAD_REQUEST' && error.response?.data) {
      const data = error.response?.data;
      console.warn('ERR_BAD_REQUEST data', data, error)

      if (data.errors) return getDescription(data.errors); // ?
      return getDescription(data); // ?
    } else if (error.response == undefined && error.status == undefined) {
      return `Server non raggiungibile: ${error.message}`
    } else {
      if (error.response) { // The request was made and the server responded with a status code
        if (error.response.data.title) {
          return `${error.response.data.title}`
        } else {
          return error.response.statusText;
        }
      }
    }

    // Access to config, request, and response
    // TODO handle http error
    // TODO: handle aspnet core error
  } else {
    return error.message
  }

  return "Errore sconosciuto";
};

//interface ValidationErrorResponse {
//  errors: {
//    [key: string]: string[];
//  };
//}
