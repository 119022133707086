<template>
  <div>
    <div class="carousel">

      <v-carousel continuous cycle height="500" show-arrows="hover">

        <v-carousel-item
          src="../assets/carousel/_PAD5613.webp"
          cover
        ></v-carousel-item>

        <v-carousel-item
          src="../assets/carousel/_PAD5992.webp"
          cover
        ></v-carousel-item>

        <v-carousel-item
          src="../assets/carousel/2017-11-04 13.45.49.webp"
          cover
        ></v-carousel-item>

        <v-carousel-item
          src="../assets/carousel/2017-11-08 12.22.53.webp"
          cover
        ></v-carousel-item>

        <v-carousel-item
          src="../assets/carousel/BML2018_ (203).webp"
          cover
        ></v-carousel-item>

        <v-carousel-item
          src="../assets/carousel/BML2018_ (513).webp"
          cover
        ></v-carousel-item>

        <v-carousel-item
          src="../assets/carousel/BML2018_ (610).webp"
          cover
        ></v-carousel-item>

        <v-carousel-item
          src="../assets/carousel/BML2018_ (622).webp"
          cover
        ></v-carousel-item>

        <v-carousel-item
          src="../assets/carousel/BML2018_ (702).webp"
          cover
        ></v-carousel-item>

        <v-carousel-item
          src="../assets/carousel/BML2018_ (1791).webp"
          cover
        ></v-carousel-item>

        <v-carousel-item
          src="../assets/carousel/BML2018_ (1819).webp"
          cover
        ></v-carousel-item>

      </v-carousel>

    </div>

    <div class="pa-4 ">
      <h1 class="text-center mt-9 mb-2">BEVI MAGNA LONGA</h1>
      <h2 class="text-center mb-6 mb-3">28 Luglio 2024</h2>

      <div class="content" style="margin: 1cm auto 3cm; max-width:20cm">
        È la manifestazione che dal 2005 rappresenta il Capodanno dell’estate della valle
        del Savio. Nata da un’idea creativa di alcuni giovani Alferesi, la BeviMagnaLonga
        si è propagata nel tempo ed ora è conosciuta in tutta la Romagna. Si tratta di una
        passeggiata enogastronomica che da 18 anni caratterizza l’ultima domenica di
        Luglio lungo un percorso di circa 5 Km. La fusione tra natura, buon vino e ottimi
        piatti fanno della manifestazione un evento unico nel suo genere.<br/>
        Giovani e meno giovani si ritrovano fin dalle 10 del mattino per percorrere il
        sentiero che attraversa i luoghi più significativi di Alfero, fino a raggiungere il bosco
        locale: il castagneto più grande della Romagna. Le delizie culinarie,
        sapientemente preparate dalle attività locali, sono abbinate agli ottimi vini
        dell’azienda Zavalloni.<br/>
        Tutto il corso della giornata è animato da intrattenimento musicale: dal gruppo
        itinerante <b>Be Diesis</b>, i travolgenti <b>ANTiPOP</b> e, all’ultima tappa, al <i>Parco del
        Casone</i> dove ha inizio l'AFTER BEVIMAGNALOGNA con lo spettacolo della
        <b>REVOLUTION live band</b>. A seguire <b>Niko P.</b>, <b>Deejay Led</b> e <b>Voice Vemax</b> pronti a
        farci saltare e divertire fino a notte inoltrata.
      </div>

      <div class="text-center mt-6 mb-10">


        <v-card color="#DD3333" :to="{name:'buy-tickets'}"
                class="vcardticket ">

          <v-row no-gutters style="background-color: rgba(0,0,0,.4)" class="pt-3 pb-3">


            <v-col cols="12" md="3" class="d-flex flex-column justify-center hidden-sm-and-down">
              <v-card-title>BML2024</v-card-title>
              <v-card-subtitle class="text-body-1">28/07</v-card-subtitle>
            </v-col>


            <v-col>


              <v-card-actions class="text-overlay pa-8 ma-9">
                <v-btn class="noshadow" :to="{name:'buy-tickets'}" color="white" variant="flat" block size="large">
                  Acquista
                  biglietto
                </v-btn>
                <!--<v-spacer></v-spacer>
                <span class="title mr-4">€ 40.00</span>-->
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card>


      </div>

      <div>

        <h3>Ricordiamo che</h3>
        <v-list>

          <v-list-item>
            La manifestazione si terrà anche in caso di pioggia.
          </v-list-item>

          <v-list-item>
            In caso di rinuncia, il pagamento del biglietto non sarà rimborsato.
          </v-list-item>

          <v-list-item>
            Per partecipare alla BeviMagnaLonga accorrerà <strong>essere soci della PRO LOCO DI
            ALFERO</strong>. L'acquisto della tessera è incluso in questo acquisto e calcolato automaticamente.
            <a href="https://alfero.net/bevimagnalonga/" title="Regolamento BML" target="_blank">Ulteriori Info sul sito
              Alfero.net</a>
          </v-list-item>

          <v-list-item>
            Per prenotare le magliette ufficiali invia una mail a <a title="Prenotazione magliette BML"
                                                                     href="mailto:magliebml@alfero.net" target=_blank>magliebml@alfero.net</a>,
            potranno essere ritirate il giorno della manifestazione e quello precedente. Le maglie ordinate e non
            ritirate
            entro le ore
            11.30 saranno rimesse in vendita.
          </v-list-item>

        </v-list>

      </div>


      <v-row>
        <!-- Prima colonna (6 colonne di larghezza) -->
        <v-col>
          <!-- https://github.com/apexcharts/vue3-apexcharts -->

        </v-col>
        <v-col>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang='ts'>
import { Api } from '@/_api';

export default {
  name: "view-homepage",
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {
  },
};
</script>


<style scoped>

.vcardticket {
  margin: 5mm auto 3cm;
  max-width: 666px;
  transition: filter 0.3s;
  background-image: url('https://alfero.net/wp-content/uploads/2020/06/slide-alfero-1.jpg');
  background-size: cover;
  filter: brightness(100%);

  color: white; /* Testo bianco per contrasto elevato */
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
}

.vcardticket:hover {
  filter: brightness(120%);
}

.noshadow {
  text-shadow: none
}

</style>
