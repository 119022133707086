<template>
  <div class="pa-4">

    <div v-if="showTicketSelection">
      <h1 class="mb-4">Biglietti collegati a questo profilo</h1>

      <v-container class="mb-8">
        <v-row>
          <!-- Ciclo su tutti gli elementi nell'array tickets -->
          <div v-if="!isStarting && relations.length === 0">Nessun biglietto associato</div>


          <v-col cols="12" sm="6" md="6" lg="4" v-for="ticketRelation in relations" :key="ticketRelation.id">

            <v-skeleton-loader type="card" v-if="isStarting"></v-skeleton-loader>
            <v-card v-if="!isStarting"
                    :color="ticketRelation.relationship === Api.RelationStatus.Owner ? 'purple-lighten-4' : 'secondary'"
                    :disabled="!ticketRelation.ticket.enabled">

              <v-card-title>
                <!-- Titolo del biglietto e anno di validità -->
                {{ ticketRelation.ticket.name }}
              </v-card-title>
              <v-card-subtitle>
                <!-- Nome completo dell'utente e relazione con il biglietto -->
                {{ ticketRelation.ticket.personalData.displayName }} - {{ ticketRelation.ticket.personalData.taxId }}
                <!-- - {{ ticketRelation.relationship }} -->
              </v-card-subtitle>
              <v-card-text>
                <!-- Dettagli del biglietto e dell'utente -->
                <div><strong>Email:</strong> {{ ticketRelation.user.personalUserData.emailAddress }}</div>
                <div><strong>Tel.</strong> {{ ticketRelation.user.personalUserData.phoneNumber }}</div>
                <div><strong>Importo:</strong> {{ formatPrice(ticketRelation.ticket.amount) }}</div>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  v-if="ticketRelation.ticket.enabled"
                  color="primary"
                  :to="{ name: 'transfer-ticket', params: { ticketId: ticketRelation.ticketId }  }">
                  Trasferisci biglietto
                </v-btn>
                <v-btn v-else>Biglietto non attivo</v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="ticketRelation.ticket.enabled" icon="$qrCode"
                       v-on:click="downloadQrCode(ticketRelation.ticket.id)"></v-btn>
              </v-card-actions>
            </v-card>

          </v-col>
        </v-row>
      </v-container>
    </div>


    <h1 class="mb-4">Trasferimento Biglietto</h1>

    <div>Con questa procedura puoi cedere il tuo biglietto ad un altro utente.</div>

    <div class="pa-10">
      <ol>
        <li>L'utente
          <v-chip color="blue">destinatario</v-chip>
          deve essere registrato, se ancora non l'ha fatto deve registrarsi su questo sito.
        </li>
        <li>Seleziona il ticket da cedere.</li>
        <li>Inserisci l'indirizzo email del
          <v-chip color="blue">destinatario</v-chip>
          nel campo sottostante per iniziare la procedura.
        </li>
        <li>Il destinatario riceverà una email con le istruzioni per versare la <strong>quota associativa Pro
          Loco</strong> (<strong>la quale è personale e non trasferibile</strong>), completato il pagamento della
          tessera, il
          biglietto sarà trasferito al nuovo proprietario.
        </li>
        <li>Il biglietto trasferito non sarà più utilizzabile dal precedente
          <v-chip color="purple">proprietario</v-chip>
          .
        </li>

        <li>Al termine dell'operazione verrà inviata una email di conferma con l'esito della transazione.</li>
      </ol>

      NB: non sono previsti rimborsi, lo scambio è da ritenersi tra privati.
    </div>


    <v-alert type="success" dense max-width="500"
             style="margin: 1cm auto 3cm"
             v-if="procedureSucceeded"
    >
      <div>La procedura è stata completata correttamente.</div>
      <div>L'utente riceverà una email con le istruzioni.</div>
    </v-alert>


    <v-container v-if="ttInfo && ttInfo.stateInfo === Api.StateInfoEnum.Error">
      <v-alert type="error" dense max-width="500"
               style="margin: 1cm auto 3cm"
      >
        Qualcosa è andato storto
      </v-alert>
    </v-container>


    <v-container v-if="ttInfo && ttInfo.stateInfo === Api.StateInfoEnum.Found">

      <v-alert v-if="ttInfo.transfer.status === Api.TransferStatus.Pending"
               type="info" dense max-width="500"
               style="margin: 1cm auto 3cm"
      >
        La richiesta di trasferimento è in attesa
      </v-alert>

      <v-alert v-if="ttInfo.transfer.status === Api.TransferStatus.Accepted"
               type="success" dense max-width="500"
               style="margin: 1cm auto 3cm"
      >
        La richiesta di trasferimento è stata accettata
      </v-alert>

      <v-alert v-if="ttInfo.transfer.status === Api.TransferStatus.Rejected"
               type="warning" dense max-width="500"
               style="margin: 1cm auto 3cm"
      >
        La richiesta di trasferimento è stata rifiutata
      </v-alert>
    </v-container>


    <div class="pa-10" v-if="showStartProcedure">
      <v-form v-model="formValid" @submit.prevent="submitForm"
              :disabled='$api.isLoading'>
        <v-text-field v-model="emailAddress" label="Email destinatario" placeholder="destinaratio@email.com"
                      color="blue"
                      type="email"
                      :rules="[rules.required, rules.email]"
                      :readonly='$api.isLoading'
                      prepend-inner-icon="$at"
                      block
        >
        </v-text-field>
        <v-btn type="submit" size="large" class="mt-2 mt-sm-0" color="primary" block :loading="$api.isLoading">Inizia
          procedura
        </v-btn>
      </v-form>
    </div>

  </div>
</template>


<script setup lang='ts'>
const rules = {
  required: (value: string) => !!value || 'Campo obbligatorio',
  email: (value: string) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(value) || 'Indirizzo Email non valido';
  },
}
</script>

<script lang='ts'>
import { Api } from '@/_api';
import { saveAs } from 'file-saver';

import { TYPE, useToast } from 'vue-toastification';

const toast = useToast();

export default {
  name: "transfer-ticket-view",
  data() {
    return {
      isStarting: true,
      relations: Array<Api.TicketRelationsDto>(),
      formValid: false,
      ticketId: '',
      emailAddress: '',
      procedureSucceeded: false,
      ttInfo: {} as Api.TransferInfoDto,
    }
  },
  computed: {
    ticketSelected() {
      return !!this.ticketId;
    },
    showTicketSelection() {
      return !this.ticketSelected && !this.procedureSucceeded;
    },
    showStartProcedure() {
      return (!this.isStarting &&
          this.ticketId &&
          !this.procedureSucceeded)
        && ((this.ttInfo.stateInfo === Api.StateInfoEnum.Nothing)
          || (this.ttInfo.transfer.status === Api.TransferStatus.Rejected));
    }
  },
  methods: {
    async submitForm() {
      if (this.$api.isLoading) return;

      // inizializza la richiesta
      const dto = new Api.TicketTransfer_Request({ ticketId: this.ticketId, emailAddress: this.emailAddress });
      await this.$api.TicketTransferClient.submitTransferRequest(dto);

      this.procedureSucceeded = true;
      this.emailAddress = '';
    },
    formatPrice(value: number | undefined) {
      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      if (value === undefined) return formatter.format(0);
      return formatter.format(value);
    },
    async downloadQrCode(ticketId: string) {

      const fileResponse = await this.$api.TicketsClient.downloadQrCode(ticketId);

      //saveAs(fileResponse.data, 'Biglietto BML.pdf');

      const url = window.URL.createObjectURL(new Blob([fileResponse.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Biglietto BML.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();

      //const url = window.URL.createObjectURL(fileResponse.data);
      //// Crea un link e simula un click per scaricare il file
      //const link = document.createElement('a');
      //link.click();
    }
  },
  async created() {
    try {

      this.ticketId = this.$route.params.ticketId as string;

      if (!this.ticketId) {
        // recupero la lista dei biglietti
        this.relations = await this.$api.TicketTransferClient.getRelatedTickets();
      } else {
        const ttInfo = await this.$api.TicketTransferClient.checkTransferStatus(this.ticketId);
        this.ttInfo = ttInfo;
      }
    } finally {
      this.isStarting = false;
    }
  },
  mounted() {
  },
};
</script>
