<template>
  <v-container>
    <div class="d-flex align-center justify-center">
      <Registration></Registration>
    </div>
  </v-container>
</template>

<script setup lang='ts'>

import Registration from '@/components/User/Registration.vue';

</script>

<script lang='ts'>
import { ref } from 'vue';

export default {
  name: "login-view",
  components: {
    Registration
  },
  data() {
    return {}
  },
  methods: {},
  created() {
  },
  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>
