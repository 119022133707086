<template>
  <v-row>
    <v-col cols="12" xs="12" sm="6" md="4">
      <v-text-field v-model.trim="personalUserData.firstName" :rules="[rules.required]" label="Nome"
                    autocomplete='given-name' density="compact" required></v-text-field>
    </v-col>
    <v-col cols="12" xs="12" sm="6" md="4">
      <v-text-field v-model.trim="personalUserData.lastName" :rules="[rules.required]" label="Cognome"
                    autocomplete='family-name' density="compact" required></v-text-field>
    </v-col>

    <v-col cols="12" xs="12" sm="6" md="4"><!-- TODO: fare un componente? -->

      <!-- WOW un date picker nativo che funziona -->
      <v-text-field label="Data di nascita" type="date"
                    ref="dateField"
                    v-model="formattedDate"
                    placeholder="Data di nascita"
                    v-on:click:append-inner="triggerNativePicker"
                    :rules="[rules.required, rules.validDate]"
                    append-inner-icon="$calendar"
                    density="compact"
                    :min="minDate"
                    :max="maxDate"
      ></v-text-field>
    </v-col>

    <v-col cols="12" xs="12" sm="6" md="4">
      <v-text-field v-model.trim="personalUserData.taxId" :rules="[rules.required, rules.taxId]" label="Codice Fiscale"
                    autocomplete='taxid' density="compact" required></v-text-field>
    </v-col>

    <v-col cols="12" xs="12" sm="6" md="4">
      <v-text-field v-model.trim="personalUserData.placeOfBirth" autocomplete="place-of-birth" label="Luogo di Nascita"
                    :rules="[rules.required]"
                    density="compact"></v-text-field>
    </v-col>


    <v-col cols="12" xs="12" sm="6" md="4">
      <!-- PHONE NUMBER -->
      <v-text-field hide-details="auto" v-model="personalUserData.phoneNumber" label="Telefono" autocomplete='tel'
                    density="compact"
                    :rules="[rules.required, rules.phone]"
                    prepend-inner-icon="$phone"
                    required></v-text-field>
    </v-col>
    <v-col cols="12" xs="12" sm="6" md="4">
      <!-- EMAIL ADDRESS -->
      <v-text-field v-model.trim="personalUserData.emailAddress" hide-details="auto" autocomplete="email"
                    label="Indirizzo Email"
                    :rules="[rules.required, rules.email]"
                    prepend-inner-icon="$at"
                    density="compact"
                    :disabled="emailDisabled"
      ></v-text-field>
    </v-col>


    <v-col cols="12" xs="12" sm="6" md="4">
      <v-text-field v-model.trim="personalUserData.residentIn" hide-details="auto" autocomplete="street-address"
                    label="Indirizzo di Residenza"
                    :rules="[rules.required]"
                    density="compact"></v-text-field>
    </v-col>

    <v-col cols="12" xs="12" sm="6" md="4">
      <v-text-field v-model.trim="personalUserData.city" hide-details="auto" autocomplete="address-level2"
                    label="Città di Residenza"
                    :rules="[rules.required]"
                    density="compact"></v-text-field>
    </v-col>
    <!--
          <v-col cols="12" xs="12" sm="6" md="4">
            <v-text-field v-model="personalUserData.postalCode" autocomplete="postal-code"
              label="Codice Postale" density="compact"></v-text-field>
          </v-col>-->

  </v-row>

  <!--<pre>{{ personalUserData }}</pre>-->

</template>

<script lang='ts'>
import { Api } from '@/_api';
import { DateTime } from 'luxon';
import * as CodiceFiscaleUtils from '@marketto/codice-fiscale-utils';
import useUserStore from "@/store/identityStore";
import { computed } from 'vue';

// TODO: l'admin deve poter caricare tutto senza controlli ...

export default {
  name: 'personal-user-data-component',
  props: {
    modelValue: {
      type: Object as () => Api.PersonalUserData,
      required: true
    },
    emailDisabled: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  setup(props, { emit }) {
    const identityStore = useUserStore();
    const rules = computed(() => ({
      required: value => {
        if (identityStore.isAdministrator) return true;
        return !!value || 'Campo obbligatorio'
      },
      taxId: (value: string) => {
        //if (identityStore.isAdministrator) return true;
        try {
          return CodiceFiscaleUtils.Validator.codiceFiscale(value).matchPersonalInfo(
              {
                firstName: props.modelValue.firstName,
                lastName: props.modelValue.lastName,
                date: props.modelValue.birthDate
              })
            || 'Non corrisponde con le anagrafiche'
        } catch {
          return 'Input non valido'
        }
      },
      validDate: (value: string) => {
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        if (!regex.test(value)) return false;
        //if (identityStore.isAdministrator) return true;
        let dt = DateTime.fromISO(value);
        return dt.isValid || 'Data di nascita non valida'
      },
      email: (value: string) => {
        if (identityStore.isAdministrator) return true;
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(value) || 'Indirizzo Email non valido';
      },
      phone: (value: string) => {
        if (identityStore.isAdministrator) return true;
        const pattern = /^(?:\+?\d{1,3})?\s?-?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/;
        return pattern.test(value) || 'Numero di telefono non valido';
      }
    }));

    return { identityStore, rules }
  },
  emits: ['update:modelValue'],
  watch: {
    'personalUserData.birthDate'(newVal, oldVal) {
      if (newVal !== oldVal) {
        setTimeout(() => (this.modal = false), 100)
      }
    },
    'personalUserData.taxId'(newVal: string, oldVal: string) {
      if (newVal !== oldVal) {
        this.personalUserData.taxId = newVal.toUpperCase()
      }
    }
  },
  data() {
    return {
      modal: false,
    }
  },
  computed: {
    personalUserData: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    formattedDate: {
      get() {
        const date = this.personalUserData.birthDate;
        if (!date) return undefined;

        const dt = DateTime.fromJSDate(date);
        return dt.toFormat('yyyy-MM-dd');
      },
      set(newValue: string) {
        const dt = DateTime.fromISO(newValue);
        if (dt.isValid) {
          this.personalUserData.birthDate = new Date(newValue);
        } else {
          this.personalUserData.birthDate = undefined!; // IMPOSTATO A UNDEFINED QUINDI TRIGGERA IL CONTROLLO
        }
      }
    },
    maxDate() {
      return DateTime.now().toISODate();
    },
    minDate() {
      return DateTime.fromISO('1910-01-01').toISODate();
    },
  },
  methods: {
    triggerNativePicker() {
      const dateInput = this.$refs.dateField as HTMLInputElement;
      dateInput.showPicker();
    },
    supportsDateInput() {
      var input = document.createElement('input');
      input.setAttribute('type', 'date');

      var notADateValue = 'not-a-date';
      input.setAttribute('value', notADateValue);

      return input.value !== notADateValue;
    },
    updateDate(e) {
      const newDateString = e.target.value;
      this.personalUserData.birthDate = new Date(newDateString);
      console.log(e, newDateString, '=>', this.personalUserData.birthDate);
    },
    toUpper(data: string) {
      data = data.toUpperCase();
    }
  },
  mounted() {
    console.log('TYPE', this.personalUserData?.birthDate, typeof this.personalUserData?.birthDate)

    if (typeof this.personalUserData?.birthDate === 'string') {
      this.personalUserData.birthDate = new Date(this.personalUserData.birthDate);
      console.warn('typeof this.personalUserData?.birthDate was string');
    }

    if (this.personalUserData?.birthDate instanceof Date) {

    }

  },
};
</script>


<style scoped>

</style>
