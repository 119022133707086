<template>
  <div class="pa-4">
    <v-container class="d-flex justify-center mt-8">
      <v-img alt="Alfero.net" src="@/assets/4x3bevi_min.png" max-width="150" class="noselect"></v-img>
    </v-container>

    <v-window v-model="step" class="justify-center text-center mb-8" direction="vertical" :touch=false>

      <v-window-item :value="'ask_email'">
        <v-form class="box" ref="emailForm" v-model="loginFormValid" @submit.prevent="onCheckUsername"
                :disabled='$api.isLoading'>

          <v-text-field v-model="username" color="primary" name="username" type="email" :rules="[rules.required]"
                        :readonly='$api.isLoading' clearable autocomplete='username' class="mb-2"
                        placeholder='Indirizzo email'
                        variant='outlined' prepend-inner-icon="$at" rounded>
          </v-text-field>
          <div class="text-caption text-grey-darken-1">
            Questa è la mail con cui accederai al servizio
          </div>

          <div> <!-- v-card-actions -->
            <v-btn type="submit" color="primary" size="large" class="mt-4"
                   :loading='$api.isLoading && loginFormValid'
                   :disabled='!loginFormValid' block rounded>Verifica
            </v-btn>
          </div>
        </v-form>

      </v-window-item>

      <v-window-item :value="'say_reset_email'">

        <v-alert type="info" class="text-left" max-width="500" style="margin: 5mm auto">
          Ti abbiamo inviato una email con la procedura per impostare la password
        </v-alert>

      </v-window-item>

      <v-window-item :value="'login'">

        <v-form class="box" v-model="loginFormValid" @submit.prevent="onSubmitLogin" :disabled='$api.isLoading'>
          <!-- DISABLED USERNAME / EMAIL -->
          <v-text-field v-model="username" color="primary" name="username" type="email" :rules="[rules.required]"
                        :readonly='true' autocomplete='username' class="mb-2"
                        placeholder='Indirizzo email'
                        variant='outlined' prepend-inner-icon="$at" rounded>
          </v-text-field>

          <!-- PASSWORD -->
          <v-text-field v-model="password" color="primary" name="password" :type="visible ? 'text' : 'password'"
                        :rules="[rules.required]" :readonly='$api.isLoading' autocomplete='current-password'
                        :append-inner-icon="visible ? '$eye-off' : '$eye'"
                        @click:append-inner="visible = !visible"
                        placeholder='Inserisci la password' variant='outlined' bg-color='white'
                        prepend-inner-icon='$lock' ref="passwordInput" rounded>
          </v-text-field>


          <div> <!-- v-card-actions -->
            <v-btn type="submit" color="primary" size="large"
                   :loading='$api.isLoading && loginFormValid'
                   :disabled='!loginFormValid || $api.isLoading' block rounded>Accedi
            </v-btn>
          </div>


          <v-card-actions>
            <v-btn v-on:click="onForgotPassword" :disabled="$api.isLoading"
                   class="mt-4 text-caption text-decoration-none text-orange"
                   rounded block>
              Password dimenticata?
            </v-btn>
          </v-card-actions>


          <v-card-actions>
            <v-btn color="secondary" class="mt-4" block rounded v-on:click="onReset" :disabled="$api.isLoading">
              Annulla
            </v-btn>
          </v-card-actions>
        </v-form>

      </v-window-item>


      <v-window-item :value="'register'">

        <Registration :email="username" v-on:signin="onReset"></Registration>

      </v-window-item>
    </v-window>

  </div>
</template>

<script setup lang='ts'>

import LoginComponent from '@/components/User/Login.vue';
import { Api } from '@/_api';

const rules = {
  required: value => !!value || 'Campo obbligatorio',
}

</script>

<script lang='ts'>
import { ref } from 'vue';
import useUserStore from "@/store/identityStore";
import { useToast, TYPE } from "vue-toastification";
import Registration from '@/components/User/Registration.vue';

const toast = useToast();

export default {
  name: 'login-sequential-view',
  components: { Registration },
  data() {
    return {
      loginFormValid: false,
      step: 'ask_email',
      username: '',
      password: null,
      visible: false,
    };
  },
  computed: {},
  methods: {
    onReset() {
      this.username = '';
      this.password = null;
      this.step = 'ask_email';
      this.loginFormValid = false;
    },
    async onCheckUsername() {
      if (!this.username) return;

      let response = await this.$api.AccountClient.check(this.username);
      if (response.requiredAction == Api.CheckAction.Login) {
        this.step = 'login'; // STEP ASK PASSWORD

        await this.$nextTick();
        const passwordInput = this.$refs.passwordInput as HTMLInputElement;
        passwordInput.focus();
      } else if (response.requiredAction == Api.CheckAction.HaveToSetPassword) {
        this.step = 'say_reset_email'; // STEP SENT RESET

      } else if (response.requiredAction == Api.CheckAction.Register) {
        this.step = 'register'; // TIME TO CREATE ACCOUNT

      } else {
        toast("Errore strambo", { type: TYPE.ERROR });
      }
    },
    onForgotPassword() {
      var dto = Api.ForgotPasswordRequest.fromJS({ email: this.username })!;
      this.$api.AccountClient.forgotPassword(dto)
        .then(response => {
          this.step = 'say_reset_email';
        })
    },
    async onSubmitLogin() {
      //const formEmail = this.$refs.emailForm as HTMLFormElement;
      //const { valid } = await formEmail.validate();
      //if (!valid) return;

      const identityStore = useUserStore();

      const dto = Api.LoginRequestDto.fromJS({
        email: this.username,
        password: this.password,
        rememberMe: true
      })!;

      try {
        let response = await this.$api.AccountClient.loginPOST(dto);
        identityStore.setUser(response.user);
        this.$router.push({ name: response.redirect?.name });

      } catch (e) {
        console.error(e)
      }
    }
  },
  created() {
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
