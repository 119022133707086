<template>
  <v-app class="bg-grey-lighten-3">

    <router-view v-slot="{ Component }">
      <component :is="Component"/>
    </router-view>

    <!--
    <transition name="router-fade" mode="out-in">
      <div v-if="route.meta.keepAlive">
        <router-view v-slot="{ Component, route }">
          <keep-alive>
            <component :is="Component" :key="route.path" />
          </keep-alive>
        </router-view>
      </div>
      <div v-else>
        <router-view v-slot="{ Component, route }">
          <component :is="Component" :key="route.path" />
        </router-view>
      </div>
    </transition>-->


  </v-app>
</template>

<script setup lang="ts">
/*

import { watch, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const transitionName = computed(() => route.meta.transition);


watch(route, (to, from) => {
  const keepAlive = !!route.meta.keepAlive;
  const toDepth = to.fullPath.split('/').filter(n => n).length;
  const fromDepth = from.fullPath.split('/').filter(n => n).length;
  const matchedComponents = router.currentRoute.value.matched;

  const componentName = matchedComponents[matchedComponents.length - 1].components?.default.name;

  //console.warn('keepAlive', keepAlive, matchedComponents);
  if (!keepAlive) {
    // Gestisci l'esclusione del componente da keep-alive qui
  }

  const newTransition = toDepth < fromDepth ? 'slide-left' : 'slide-right';
  route.meta.transition = newTransition;

  console.log('navigating', from.fullPath,
    'to', to.fullPath,
    'transition', newTransition,
    componentName
  );
});
*/

// https://vitejs.dev/guide/build#load-error-handling
window.addEventListener('vite:preloadError', (event: Event) => {
  console.error('preloadError', event)
  window.location.reload();
});
</script>


<script lang='ts'>

export default {
  name: "app-view",
  data() {
    return {}
  },
  methods: {},
  mounted() {

    console.log(typeof Date); // Should log 'function'
    console.log(new Date());  // Should log the current date and time

  },
};
</script>
