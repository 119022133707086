<template>

  <v-card-title>
    Creazione nuova Tessera
  </v-card-title>

  <v-container>
    <!-- Anagrafiche -->
    <v-form ref='formpd' class='mb-10' v-model="formValid" @submit.prevent="submitPersonalDataForm"
            :disabled='isSavingPD'>

      <PersonalUserDataForm v-model="personalUserData"/>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model="amount"
            label="Importo in Euro"
            prefix="€"
            density="comfortable"
          ></v-text-field>
        </v-col>

        <v-col cols="4">
          <v-text-field
            type="number"
            v-model="number"
            label="Numerazione"
            density="comfortable"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <!-- Associa a utente -->
          Lascia vuoto per creare l'utente, cerca l'utente per associarlo

          <v-autocomplete
            v-model="selectedUser"
            :items="items"
            :loading="isLoadingUsers"
            label="Assegna utente"
            variant="outlined"
            @update:search="onInput"
            no-filter
            clearable
          ><!-- v-model:search-input="search" -->
            <template v-slot:chip="{ props, item }">
              <v-chip
                v-bind="props"
                :text="item.raw.username"
              ></v-chip>
            </template>

            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props"
                           :title="item.raw.username"
              >
                {{ item.raw.personalUserData.displayName }} - <span
                class="text-caption">{{ item.raw.personalUserData.taxId }}</span>
              </v-list-item>
            </template>

          </v-autocomplete>
        </v-col>

        <v-col>

        </v-col>
      </v-row>


      <v-row>
        <v-col>
          <v-btn type="submit" color="primary" :loading='isSavingPD && formValid'>
            Crea Tessera
          </v-btn>
        </v-col>
      </v-row>


    </v-form>

    <!-- NO: lista di relazioni perché questo è il builder? magari + avanti -->
  </v-container>
</template>


<script lang='ts'>
import { Api } from '@/_api';
import PersonalUserDataForm from '@/components/Store/PersonalUserDataForm.vue';
import { TYPE, useToast } from 'vue-toastification';

const toast = useToast();

export default {
  name: "create-membership-component",
  component: { PersonalUserDataForm },
  emits: {
    'update:modelValue': (value: Api.MembershipDto) => true,
    'created': (value: Api.MembershipDto) => value && value.id.length > 0,
  },
  data() {
    return {
      isSavingPD: false, // salvataggio personal data
      formValid: false,
      personalUserData: {} as Api.PersonalUserData,

      // search bar
      selectedUser: null as Api.ApplicationUserDto | null,
      items: Array<Api.ApplicationUserDto>(),
      search: null,
      isLoadingUsers: false, // caricamento ricerca utenti

      // costo tessera
      amount: 10,
      number: 0,
    }
  },
  computed: {
    formPD() {
      return this.$refs.formpd as HTMLFormElement
    }
  },
  methods: {
    async submitPersonalDataForm(e) {
      const { valid } = await this.formPD.validate();
      if (!valid) {
        toast("Compila correttamente il form", { type: TYPE.ERROR });
        return
      }
      if (!this.formValid) return;

      this.isSavingPD = true;

      let dto = new Api.CreateMembershipFromPersonalDate();
      dto.personalUserData = new Api.PersonalUserData(this.personalUserData);
      dto.amount = this.amount;
      dto.number = this.number;
      dto.assignToUserId = this.selectedUser?.id;

      this.$api.MembershipClient.createFromPersonalData(dto)
        .then(response => {
          toast("Tessera creata correttamente: " + response.id, { type: TYPE.SUCCESS });

          this.$emit('created', response);
          this.selectedUser = null;
          this.personalUserData = new Api.PersonalUserData();
        })
        .catch(error => {
          console.error("Errore creazione tessera:", error);
          toast(error, { type: TYPE.ERROR });
        })
        .finally(() => {
          this.isSavingPD = false;
        });
    },
    onInput(search: string) {
      if (!search) return;

      this.isLoadingUsers = true;
      this.$api.UserClient.searchUsers(search)
        .then(response => {
          this.items = response;
        })
        .catch(error => {
          console.error("Errore nella ricerca:", error);
        })
        .finally(() => {
          this.isLoadingUsers = false;
        });
    },
  },
  mounted() {
  },
};
</script>
