<template>
  <div class="pa-4">

    <h1 class='mb-4'>Anagrafiche</h1>

    <v-form ref='formpd' class='mb-10' v-model="formValid" @submit.prevent="submitPersonalDataForm"
            :disabled='$api.isLoading'>

      <PersonalUserDataForm v-model="personalUserData" :emailDisabled="true"/>

      <v-row>
        <v-col>
          <v-btn type="submit" color="primary" :loading='$api.isLoading && formValid'>
            Salva modifiche
          </v-btn>
        </v-col>
      </v-row>

    </v-form>

    <v-row class='mb-5'>

      <!-- COLONNA CAMBIO PASSWORD-->
      <v-col cols='12' sm='6'>
        <PasswordChange></PasswordChange>
      </v-col>

    </v-row>

    <v-row class='mb-5'> <!--justify-center -->
      <v-col cols='12' sm='6'></v-col>
      <v-col cols='12' sm='6'></v-col>
    </v-row>

  </div>
</template>


<script lang='ts'>
import { Api } from '@/_api';
import PersonalUserDataForm from '@/components/Store/PersonalUserDataForm.vue';
import { useUserStore } from '@/store/identityStore';

import PasswordChange from '@/components/User/Profile/PasswordChange.vue'

import { useToast, TYPE } from "vue-toastification";

const toast = useToast();


export default {
  name: "users-profile-view",
  components: { PasswordChange, PersonalUserDataForm },
  data() {
    return {
      formValid: false,
      panel_password_reset: false,
      loading: false,
      personalUserData: {} as Api.PersonalUserData,
      showAvatarPicker: false
    }
  },
  computed: {
    formPD() {
      return this.$refs.formpd as HTMLFormElement
    }
  },
  created() {
    const identityStore = useUserStore(); // get current personalData and make a copy

    if (identityStore && identityStore.user && identityStore.user.personalUserData) {
      this.personalUserData = { ...identityStore.user.personalUserData }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.formPD.validate();

    const identityStore = useUserStore();
    if (!identityStore.isAdministrator)
      if (!identityStore.user.personalUserData.taxId)
        toast("Compila le anagrafiche per iniziare l'ordine", { type: TYPE.INFO });
  },
  methods: {
    async submitPersonalDataForm(e) {
      const { valid } = await this.formPD.validate();
      if (!valid) {
        toast("Compila correttamente il form", { type: TYPE.ERROR });
        return
      }
      if (!this.formValid) return;


      // panel_password_reset === 0 aggiungi password
      const identityStore = useUserStore(); // get current personalData and make a copy

      const dto = Api.UserUpdateRequestDto.fromJS(this.personalUserData)!;
      //var dto = this.personalUserData;
      this.$api.UserClient.updatePersonalData(dto)
        .then((data) => {
          identityStore.setUser(data);
          toast("Anagrafiche aggiornate", { type: TYPE.SUCCESS });
        })
        .catch(err => {
          console.warn(err, err.personalUserData)
          toast("Salvataggio fallito, codice fiscale già esistente", { type: TYPE.ERROR });

          if (err.personalUserData) {
            err.personalUserData.birthDate = new Date(err.personalUserData.birthDate); // questo doveva auto castarsi. forse nel template non converte come per il 200
            this.personalUserData = err.personalUserData;
          }
          //if (err.code === "ERR_BAD_REQUEST") {
          //  console.warn("MAMMAPECORA")
          //}
        })
        .finally(() => {
        });
    },

  }
};
</script>
