<template>
  <v-container>


  </v-container>
</template>


<script lang='ts'>
import { Api } from '@/_api';


export default {
  name: "users-index-view",
  data() {
    return {}
  },
  methods: {},
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
