<template>
  <PasswordReset></PasswordReset>
</template>

<script setup lang='ts'>

import PasswordReset from '@/components/User/PasswordReset.vue';

</script>

<script lang='ts'>
import { ref } from 'vue';

export default {
  name: "user-reset-password",
  components: {
    PasswordReset
  },
  data() {
    return {}
  },
  methods: {},
  created() {
  },
  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>
