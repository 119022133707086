<template>
  <v-expansion-panel>
    <v-form ref="formHtml" v-model="formValid" @submit.prevent="onSubmitRecalculate" :disabled="isLoading">
      <v-expansion-panel-title color=secondary>
        <template v-slot:default="{ expanded }">
          <div class="d-flex justify-space-between align-center w-100">
            <span>{{ displayName }}</span>
            <v-progress-circular class="mr-8" size="15" v-if="isLoading" indeterminate></v-progress-circular>
            <span v-else-if="serverProducts?.length > 0" class="font-weight-bold mr-5">{{
                formatPrice(calculatedPrice)
              }}</span>
          </div>
        </template>
      </v-expansion-panel-title>

      <v-expansion-panel-text eager> <!-- EAGER: FORZA IL RENDERING NEL MOUNTED -->
        <!--<v-switch inset density="compact" v-model="product.buyForOther"
                  v-on:change="onBuyForOther"
                  label="Acquista per un'altra persona"></v-switch>-->

        <v-row>

          <v-col cols="12" xs="12" sm="6" md="4">
            <v-switch inset density="compact" v-model="product.ticket.menuCeliac"
                      label="Menù Celiaci (25 €)"></v-switch>
          </v-col>

          <v-col cols="12" xs="12" sm="6" md="4" v-if="identityStore.isAdministrator">
            <v-switch inset density="compact" v-model="product.ticket.collaborator" label="Collaboratore"></v-switch>
          </v-col>
        </v-row>

        <PersonalUserDataForm v-if="product.buyForOther" v-model="product.personalUserData"/>

        <v-divider></v-divider>

        <!--
        <v-table density='compact' class='mt-3 mb-3'>
          <thead>
          <tr>
            <th class="text-left">
              Prodotto
            </th>
            <th class="text-left">
              Costo
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in serverProducts" :key="item.name">
            <td>{{ item.name }}</td>
            <td>
              <span class='font-mono font-bold'>{{ formatPrice(item.amount) }}</span>
            </td>
          </tr>
          </tbody>
        </v-table>
-->
        <!-- Intestazione della "tabella" -->
        <v-container class="text-body-2" v-if="serverProducts?.length > 0">
          <v-row>
            <v-col class="text-left font-weight-bold">Prodotto</v-col>
            <v-col cols="2" class="text-left font-weight-bold">Costo</v-col>
          </v-row>

          <!-- Elementi della tabella -->
          <v-row
            v-for="item in serverProducts"
            :key="item.name"
          >
            <v-col>{{ item.name }}</v-col>
            <v-col cols="2" class="text-left">{{ formatPrice(item.amount) }}</v-col>
          </v-row>
        </v-container>


        <div class="mt-5">
          <v-btn type="submit" color="primary" variant='tonal' class='ma-2' :loading="isLoading">
            Salva e ricalcola
          </v-btn>

          <v-btn color="error" variant='tonal' prepend-icon='$trash' v-on:click="modalDelete = true" class="ma-2"
                 text='Elimina'></v-btn>

        </div>
      </v-expansion-panel-text>
    </v-form>

    <v-dialog v-model="modalDelete" scroll-strategy="close" width="auto">
      <v-card max-width="400" title="Conferma eliminazione" text="Vuoi eliminare questo elemento?"
              prepend-icon="$trash" class="pa-2">
        <template v-slot:actions>
          <v-btn class="ms-auto" variant='tonal' color=error text="Sì, elimina" v-on:click="onDelete"></v-btn>
          <v-btn class="ms-auto" color=gray-300 text="Annulla" v-on:click="modalDelete = false"></v-btn>
        </template>
      </v-card>
    </v-dialog>
  </v-expansion-panel>
</template>


<script lang='ts'>
import useUserStore from "@/store/identityStore";
import { Api } from '@/_api';
import { defineComponent, computed } from 'vue';

import { DateTime } from 'luxon';
import PersonalUserDataForm from './PersonalUserDataForm.vue';

import { TYPE, useToast } from 'vue-toastification';

const toast = useToast();

export default {
  name: "store-tickets-builder-single",
  props: ['modelValue'],
  emits: ['update:modelValue', 'delete', 'recalculate'],
  components: {
    PersonalUserDataForm
  },
  data() {
    return {
      opened: false,
      modalDelete: false,
      formValid: false,
      isLoading: false,
      serverProducts: [] as Api.IOrderProductDto[],
    }
  },
  setup(props, { emit }) {
    const identityStore = useUserStore();
    const rules = computed(() => ({
      required: !identityStore.isAdministrator ? (value => !!value || 'Campo obbligatorio') : true
    }));

    return { identityStore, rules }
  },
  computed: {
    formREF() {
      return this.$refs.formHtml as HTMLFormElement
    },
    product: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    formattedDate() {
      let date = this.product.personalUserData.birthDate;
      if (!date) return null;

      const dt = DateTime.fromJSDate(date);
      const f = dt.toFormat('yyyy/MM/dd');
      console.log(f)
      return f
    },
    maxDate() {
      return DateTime.now().toISODate();
    },
    minDate() {
      return DateTime.fromISO('1900-01-01').toISODate();
    },
    displayName() {
      let name = 'Biglietto';

      if (this.product.buyForOther) {
        name = `${this.product.personalUserData.firstName} ${this.product.personalUserData.lastName}`.trim() || "Biglietto";
      } else {
        name = `${this.identityStore.user.personalUserData.firstName} ${this.identityStore.user.personalUserData.lastName}`.trim() || 'Sconosciuto';
      }

      if (!this.formValid)
        name += ' (da completare)';

      return name;
    },
    calculatedPrice(): number {
      return this.serverProducts.reduce((total, product) => {
        return total + product.amount;
      }, 0);
    }
  },
  methods: {
    formatPrice(value: number) {
      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });

      return formatter.format(value);
    },
    async onSubmitRecalculate() {
      const { valid } = await this.formREF.validate();
      if (!valid) {
        toast("Compila correttamente il form", { type: TYPE.ERROR });
      }

      if (valid)
        await this.onRecalculate();

      return valid;
    },
    async onRecalculate() {
      // this.$emit('recalculate');
      // se invece di ricalcolare, invio solo questo stronzetto?

      const { valid } = await this.formREF.validate();
      if (!valid) return;

      this.isLoading = true;
      const dto = new Api.Ticket_CreateOrder_Request({
        paymentStatus: Api.PaymentStatus.Pending,
        orderStatus: Api.OrderStatus.Created,
        paymentMethod: Api.PaymentMethod.Unknown,
        products: [this.product],
      });

      try {
        const response = await this.$api.TicketsClient.verifyTicketRequest(dto);
        this.serverProducts = response.orderProducts || [];
        this.product.serverProducts = this.serverProducts;

      } finally {
        this.isLoading = false;
      }

      //this.$api.TicketsClient.verifyTicketRequest(dto)
      //  .then(response => {
      //    this.serverProducts = response.orderProducts || [];
      //    this.product.serverProducts = this.serverProducts;
      //  })
      //  .catch(error => {
      //    console.error(error);
      //  }).finally(() => {
      //  this.isLoading = false;
      //})
    },
    onDelete() {
      this.modalDelete = false;
      this.$emit('delete', this.product);
    },
    onBuyForOther(e: Event) {
      console.log(this.product.buyForOther);
      if (!this.product.buyForOther) { // se sto comprando per me imposto i miei dati (?)
        // in realtà il backend già lo gestisce, lo uso solo per mostrare il nome della UI?
        //this.product.personalUserData.emailAddress = this.identityStore.user.username;
        //this.readonly.emailAddress = true;
      }
    },
  },
  async mounted() {
    // TODO: da verificare perché non arriva tipizzato - forse causa della deserialization dello store
    if (typeof this.product.personalUserData?.birthDate === 'string') {
      this.product.personalUserData.birthDate = new Date(this.product.personalUserData.birthDate);
    }

    // se il ref sta dentro v-expansion-panel-text non funziona
    const { valid } = await this.formREF.validate();
    this.formValid = valid;


    //this.$nextTick(async () => {
    //  const { valid } = await this.formREF.validate();
    //  this.formValid = valid;
    //});

    await this.onRecalculate();
  },
  created() {
    if (this.product.serverProducts)
      this.serverProducts = this.product.serverProducts;
  }
};
</script>


<style>
.center-container {
  max-width: 500px;
  margin: auto;
}
</style>
