<template>
  <div class="pa-4">


    <h1>Acquisto biglietti BML24</h1>
    <div>Da qui puoi acquistare i biglietti per te e per altre persone.</div>

    <v-list v-if="ticketingInfoLoading">
      <v-skeleton-loader type="list-item" max-width="300"></v-skeleton-loader>
      <v-skeleton-loader type="list-item" max-width="280"></v-skeleton-loader>
      <v-skeleton-loader type="list-item"></v-skeleton-loader>
      <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
      <v-skeleton-loader type="list-item" max-width="800"></v-skeleton-loader>
    </v-list>

    <div v-if="ticketingInfoLoaded">
      <v-list>
        <v-list-item v-if="!ticketSoldOut">
          <div v-if="ticketingInfo.availableTickets >= 30">
            Sono disponibili più di <strong>{{ ticketingInfo.availableTickets }}</strong> biglietti.
          </div>

          <div v-else-if="ticketingInfo.availableTickets === 1">
            <strong>È rimasto solo un biglietto!</strong>
          </div>

          <div v-else>
            Sono ancora disponibili <strong>{{ ticketingInfo.availableTickets }}</strong>
            biglietti.
          </div>

        </v-list-item>

        <v-list-item>
          Limite per ordine: <strong>30</strong> biglietti.
        </v-list-item>

        <v-list-item>
          L'acquisto della tessera socio Pro Loco è incluso in questo ordine e calcolato automaticamente.
          <a href="https://alfero.net/bevimagnalonga/" title="Regolamento BML" target="_blank">Ulteriori info sul sito
            Alfero.net</a>.
        </v-list-item>

        <v-list-item>
          Per prenotare le magliette ufficiali invia una mail a <a title="Prenotazione magliette BML"
                                                                   href="mailto:magliebml@alfero.net">magliebml@alfero.net</a>
          indicando taglia e un nominativo di prenotazione,
          potranno essere ritirate il giorno della manifestazione e quello precedente. Le maglie ordinate e non ritirate
          entro le ore
          11.30 saranno rimesse in vendita.
        </v-list-item>

        <v-list-item>
          Non è possibile acquistare molteplici ticket per lo stesso nominativo.
        </v-list-item>

        <v-list-item>
          Il carrello sarà salvato in memoria fino al completamento dell'acquisto.
        </v-list-item>

        <v-list-item>
          <!--<strong>N.B.</strong> fino a quando il pagamento non sarà avvenuto il biglietto non vi verrà riservato.-->
          <strong>N.B.</strong> il biglietto non verrà riservato fino al completamento del pagamento.
        </v-list-item>

      </v-list>


      <v-container v-if="ticketingInfoLoaded">

        <!-- Messaggio BIGLIETTI ESAURITI -->
        <v-alert v-if="ticketSoldOut" type="error" dense max-width="500"
                 style="margin: 5mm auto">
          Al momento i biglietti sono esauriti
        </v-alert>

        <!-- Messaggio se lo store deve ancora aprire -->
        <v-alert v-if="isBeforeOpening && isBeforeClosing" type="info" dense max-width="500" style="margin: 5mm auto">
          Le iscrizioni apriranno il <strong>{{ openingTimeFormatted }}</strong>
        </v-alert>

        <!-- Messaggio chiusura -->
        <v-alert v-if="isOpen" type="warning" dense max-width="500"
                 style="margin: 5mm auto">
          Le iscrizioni chiuderanno il <strong>{{ closingTimeFormatted }}</strong>
        </v-alert>

        <!-- Messaggio se lo store è chiuso -->
        <v-alert v-if="isAfterClosing" type="warning" dense max-width="500" style="margin: 5mm auto">
          Le iscrizioni sono concluse
        </v-alert>

      </v-container>


      <v-alert v-if="!identityStore.isAdministrator && ticketingInfoLoaded && ticketingInfo.hadPendingOrder"
               color="warning" max-width="500"
               icon="$warning" style="margin:auto">
        Non puoi effettuare altri ordini se hai un ordine non completato
      </v-alert>

    </div>

    <!--<v-btn color="green" size="x-large" @click="dialogNewTicket = true">-->

    <!-- PULSANTE AGGIUNTA TICKET -->
    <div v-if="!addTicketDisabled">
      <v-btn color="green" size="x-large" @click="onClickBtnAdd" class="ma-6"
             :disabled="addTicketDisabled">
        <template v-slot:prepend>
          <v-icon color='success'>$plus</v-icon>
        </template>
        <span>Aggiungi biglietto</span>
      </v-btn>

      <v-container v-if="identityStore.isAdministrator">
        <v-row>
          <v-col cols="12" xs="12" sm="6" md="4">
            <v-select v-model="orderStatus" :items="orderStatusOptions" label="Stato Ordine" disabled></v-select>
          </v-col>

          <v-col cols="12" xs="12" sm="6" md="4">
            <v-select v-model="paymentStatus" :items="paymentStatusOptions" label="Stato Pagamento"></v-select>
          </v-col>

        </v-row>
      </v-container>

      <v-expansion-panels v-model="panels" multiple>
        <template v-for="(item, i) in cart" :key="item._stupidId">
          <SingleTicket ref="elements" v-model='cart[i]' v-on:delete="handleDelete(i, item)"
                        v-on:recalculate="onSubmitRecalculate"/>
        </template>
      </v-expansion-panels>
      <div ref='bottom'></div>


      <v-container fluid>
        <div class="mt-9" v-if="cart.length > 0">

          <v-row class="d-flex align-center">
            <v-col cols="auto">
              <v-select v-model="paymentMethod"
                        variant="solo-filled"
                        color="purple" :items="paymentMethodOptions"
                        label="Metodo di pagamento"
                        class="ma-0"
                        hide-details></v-select>
            </v-col>

            <v-col cols="auto">

              <v-btn class="ma-0" color="purple" size="x-large" @click="dialogCheckoutConfirm = true"
                     :loading='loadingCheckout' :disabled="checkoutDisabled">
                <template v-slot:append>
                  <v-icon color='purple'>$arrowRightBold</v-icon>
                </template>
                <span>Procedi al checkout</span>
              </v-btn>
            </v-col>
          </v-row>

          <div class="text-caption mt-6">Procedendo con l'ordine, accetti la nostra <a
            href="https://alfero.net/download/1506/?tmstv=1716993620" title="Informativa Privacy" target="_blank">informativa
            privacy</a> e il <a href="https://alfero.net/bevimagnalonga/" title="Regolamento BML" target="_blank">regolamento
            completo</a>.
          </div>

        </div>
      </v-container>

      <v-container class="mt-9">
      </v-container>
    </div>

    <!-- DIALOGS -->
    <v-dialog v-model="dialogNewTicket" width="auto" scroll-strategy="close">
      <v-card max-width="400" color=indigo-300 title="Per chi acquisti questo biglietto?">
        <template v-slot:actions>
          <v-btn class="ms-auto" color=primary text="PER ME" :disabled="selfTicketIsOnList"
                 @click="onClickNew(false)"></v-btn>
          <v-btn class="ms-auto" color=secondary text="PER UN'ALTRA PERSONA"
                 @click="onClickNew(true)"></v-btn>
        </template>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialogCheckoutConfirm" width="auto" transition="dialog-bottom-transition">
      <v-card max-width="400" color="indigo-300" title="Procedere al checkout?"
              text="Il tuo ordine verrà processato e validato, non sarà più possibile modificare o effettuare un nuovo ordine fino al completamento dell'ordine corrente.">
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="loadingCheckout" color="primary" @click="onClickCheckout">Procedi</v-btn>
          <v-btn :disabled="loadingCheckout" color="secondary" @click="dialogCheckoutConfirm = false">Annulla</v-btn>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang='ts'>
import SingleTicket from '@/components/Store/SingleTicketOrdering.vue';
import { useCartStore } from '@/store/cartStorage';

import { Api } from '@/_api';

import { TYPE, useToast } from 'vue-toastification';
import { v4 as uuidv4 } from 'uuid';

const toast = useToast();

// Accrocchio pazzerello per usare la shadow _stupidId
class TicketProductsWithId extends Api.TicketProducts {
  _stupidId: string;

  constructor(initValues: any) { // sostituisci 'any' con un tipo appropriato se disponibile
    super(initValues);
    this._stupidId = uuidv4();
  }
}

const defaultProduct = (): TicketProductsWithId => {
  return new TicketProductsWithId({
    buyForOther: false,
    membership: {},
    ticket: {
      menuCeliac: false,
      collaborator: false,
    },
    personalUserData: {
      firstName: '',
      lastName: '',
      emailAddress: undefined,
      taxId: undefined,
      phoneNumber: undefined,
      birthDate: new Date('01/01/1990'),
      placeOfBirth: undefined,
      residentIn: undefined,
      streetAddress: undefined,
      city: undefined,
      province: undefined,
      postalCode: undefined,
      country: undefined
    }
  });
};

import { ref, VNodeRef } from 'vue'
import useUserStore from "@/store/identityStore";
import { loading } from "@/plugins/loading";
import { DateTime } from "luxon";

const paymentMethodTranslations = {
  //[Api.PaymentMethod.Unknown]: "Sconosciuto",
  [Api.PaymentMethod.CreditCard]: "Carta di Credito",
  [Api.PaymentMethod.Cash]: "Contanti (pagamento in sede)",
  //[Api.PaymentMethod.BankTransfer]: "Bonifico Bancario",
};

export default {
  name: "store-tickets-builder-view",
  components: { SingleTicket },
  setup() {
    const cartStore = useCartStore();
    const identityStore = useUserStore();

    const elements = ref(Array<VNodeRef>());
    return { cartStore, elements, identityStore }
  },
  data() {
    return {
      privacyAccepted: false,
      dialogNewTicket: false,
      dialogCheckoutConfirm: false,
      alwaysOpen: true,
      panels: Array<number>(),

      // prodotti aggiungi al carrello
      cart: Array<Api.ITicketProducts>(),
      ticketRefs: Array<VNodeRef>(),

      loadingCheckout: false,
      dialogPaymentCanceled: false,
      //elements: Array<VNodeRef>(),
      paymentStatusOptions: Object.values(Api.PaymentStatus),
      orderStatusOptions: Object.values(Api.OrderStatus),
      // TODO: RIABILITARE
      paymentMethodOptions: [Api.PaymentMethod.CreditCard, Api.PaymentMethod.Cash].map(method => ({
        title: paymentMethodTranslations[method],
        value: method
      })),
      paymentStatus: Api.PaymentStatus.Pending,
      orderStatus: Api.OrderStatus.Created,
      paymentMethod: Api.PaymentMethod.CreditCard,

      // ticketing status
      ticketingInfoLoading: false,
      ticketingInfo: {} as Api.ShopTicketInfoDto,
    }
  },
  computed: {
    addTicketDisabled() {
      if (!this.ticketingInfoLoaded) return true;
      if (this.identityStore.isAdministrator) return false;
      return this.ticketSoldOut || this.tooManyTickets || this.ticketingInfo?.hadPendingOrder || this.loadingCheckout || this.ticketingInfoLoading || this.isAfterClosing;
    },
    checkoutDisabled() {
      if (!this.ticketingInfoLoaded) return true;
      if (this.identityStore.isAdministrator) return false;
      return !this.isOpen;
    },
    ticketSoldOut() {
      if (!this.ticketingInfo) return false;
      return this.ticketingInfo.availableTickets <= 0;
    },
    loading() {
      return loading
    },
    selfTicketIsOnList() {
      return this.cart.filter(item => !item.buyForOther).length >= 1;
    },
    tooManyTickets() {
      return this.cart.length >= 30;
    },
    // GESTIONE APERTURA STORE
    ticketingInfoLoaded() {
      if (this.ticketingInfoLoading) return false;
      return typeof this.ticketingInfo?.availableTickets === 'number';
    },
    isBeforeOpening() {
      return this.now() < this.opening;
    },
    isAfterOpening() {
      return this.now() >= this.opening;
    },
    isBeforeClosing() {
      return this.now() <= this.closing;
    },
    isAfterClosing() {
      return this.now() > this.closing;
    },
    isOpen() {
      return this.isAfterOpening && this.isBeforeClosing;
    },
    // opening date
    opening() {
      return DateTime.fromJSDate(this.ticketingInfo.openingDate, { zone: 'utc' }).toLocal();
    },
    // closing date
    closing() {
      return DateTime.fromJSDate(this.ticketingInfo.closingDate, { zone: 'utc' }).toLocal();
    },
    openingTimeFormatted() {
      return this.opening.setLocale('default').toFormat("d MMMM 'alle' HH:mm");
    },
    closingTimeFormatted() {
      return this.closing.setLocale('default').toFormat("d MMMM 'alle' HH:mm");
    },
  },
  methods: {
    now() {
      return DateTime.now();
    },
    onClickBtnAdd() {

      if (this.selfTicketIsOnList) {
        this.onClickNew(this.selfTicketIsOnList);
        return;
      }

      this.dialogNewTicket = true;
    },
    onClickNew(buyForOther: boolean) {

      const product = defaultProduct();
      product.buyForOther = buyForOther;
      this.cart.push(product);

      // Espandi l'ultimo inserito
      this.panels.push(this.cart.length - 1);

      // this.$refs.bottom.scrollIntoView({ behavior: 'smooth' });
      //this.cart.unshift(defaultProduct()); // si bugga il panel

      this.$nextTick(() => {
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: 'smooth'
        });

      });

      this.dialogNewTicket = false
    },
    updateCartItem(index, updatedProduct) {
      this.cart[index] = updatedProduct;
    },
    handleDelete(index, product) {
      const i = this.cart.indexOf(product);
      this.cart.splice(i, 1);
      this.panels.splice(i, 1);
    },
    onSubmitRecalculate() {
      const dto = new Api.Ticket_CreateOrder_Request({
        paymentStatus: this.paymentStatus,
        orderStatus: this.orderStatus,
        paymentMethod: this.paymentMethod,
        products: this.cart,
      });
      console.log('data', this.cart, 'dto', dto);

      this.$api.TicketsClient.verifyTicketRequest(dto)
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.error(error);
        })

    },
    async onClickCheckout() {
      const vm = this;
      this.loadingCheckout = true;

      try {
        for (let i in this.elements) {
          const ticketComponent = this.elements[i] as any;
          if (typeof ticketComponent.onSubmitRecalculate === 'function') {
            let valid = await ticketComponent.onSubmitRecalculate();
            if (!valid) {
              vm.loadingCheckout = false;
              return; // interrompo il flusso
            }
          } else console.warn('ticketComponent is not SingleTicket', ticketComponent);
        }

        const dto = new Api.Ticket_CreateOrder_Request({
          paymentStatus: this.paymentStatus,
          orderStatus: this.orderStatus,
          paymentMethod: this.paymentMethod,
          products: this.cart,
        });

        // se ok proseguo
        await this.$api.TicketsClient.verifyTicketRequest(dto);
        const response = await this.$api.TicketsClient.submitTicketRequest(dto);
        console.log(response);
        if (!response.url) throw new Error("undefined checkout url");
        // SE RITORNA OK CANCELLA IL BUILDER e prosegui per il pagamento

        if (response.clearCart)
          this.cartStore.clearCart();

        window.location.href = response.url;

        // lascio spinnare il loading fino all'apertura di stripe
      } catch (error) {
        vm.loadingCheckout = false;
      } finally {
        // ...
      }
    },
    async loadTicketingInfo() {
      try {
        this.ticketingInfoLoading = true;
        this.ticketingInfo = await this.$api.TicketsClient.getAvailability();

      } finally {
        this.ticketingInfoLoading = false;
      }
    }
  },
  created() {
    if (this.identityStore.isAdministrator)
      this.paymentMethod = Api.PaymentMethod.Cash;
    else
      this.paymentMethod = Api.PaymentMethod.CreditCard;
  },
  mounted() {
    const vm = this;

    window.scrollTo(0, 0);

    this.loadTicketingInfo();

    this.cart = this.cartStore.getItems;

    // bo facciamo così...
    const cartStore = useCartStore();
    cartStore.$subscribe((mutation, state) => {

      console.log('Mutation:', mutation);
      console.log('State:', state);

      this.cart = this.cartStore.getItems;
    });
  },
  beforeUnmount() {
    this.cartStore.setItems(this.cart);
  }
};
</script>
