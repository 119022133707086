import "vue-toastification/dist/index.css";
import { TYPE } from 'vue-toastification';

export default ({
  timeout: 5000,
  position: 'top-center',
  type: TYPE.DEFAULT,
  closeOnClick: false,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: false,
  transition: 'Vue-Toastification__fade',
  toastDefaults: {
    // ToastOptions object for each type of toast
    [TYPE.DEFAULT]: { toastClassName: ['noselect'] },
    [TYPE.ERROR]: {
      timeout: 10000,
      closeButton: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true, // false
    },
  },
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(
      t => t.type === toast.type
    ).length !== 0) {
      // Returning false discards the toast
      return false;
    }
    // You can modify the toast if you want
    return toast;
  }
});
