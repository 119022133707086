<template>
  <v-container fluid>
    <v-btn color="primary" prepend-icon="$plus" v-on:click="dialogCreateMembership = true">Nuova Tessera</v-btn>

    <div class="mt-5">
      <v-btn color="secondary" v-on:click="exportMemberships" :loading="$api.isLoading">
        <template v-slot:prepend>
          <v-icon>$export</v-icon>
        </template>
        <span>Esporta Tessere</span>
      </v-btn>

    </div>


    <div>
      <v-dialog v-model="dialogCreateMembership" width="auto" scroll-strategy="reposition" persistent>
        <v-card max-width="auto" color=indigo-300>

          <v-card-text>
            <CreateMembership @created="onCreated"></CreateMembership>
          </v-card-text>

          <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialogCreateMembership = false">Chiudi</v-btn>
          </template>
        </v-card>
      </v-dialog>


      <!-- DIALOG EDIT -->
      <v-dialog v-model="dialogEditMembership" width="auto" scroll-strategy="reposition" persistent>
        <v-card max-width="auto" color=indigo-300>

          <v-card-text>
            <EditMembership v-model="selectedMembership" @deleted="onDeleted"></EditMembership>
          </v-card-text>

          <template v-slot:actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialogEditMembership = false">Chiudi</v-btn>
          </template>
        </v-card>
      </v-dialog>
    </div>


    <v-text-field density="comfortable"
                  class="ma-6" v-model="query" variant='outlined' append-inner-icon="$magnify" color='primary'
                  label="Cerca per nome, cognome, codice fiscale, email..." hide-details single-line
                  @click:append-inner="onSearch"
                  @keydown.enter="onSearch"
                  clearable></v-text-field>

    <!-- SERVER TABLE -->
    <v-data-table-server density="compact" v-model:items-per-page="itemsPerPage" :headers="headers" :items="serverItems"
                         :items-length="totalItems" :loading="loading" item-value="name"
                         @update:options="loadItems"
                         @click:row="handleRowClick">

      <template v-slot:item.amount="{ item }">
        {{ formatPrice(item.amount) }}
      </template>

      <template v-slot:item.actions="{ item }">
        -
        <!--
        <v-icon size="small" @click="deleteItem(item)" color='error'>
          $delete
        </v-icon>
        -->
      </template>


      <template v-slot:tfoot>
        <div class='ma-8'></div>
      </template>

      <!--
      <template v-slot:item.actions="{ item }">
        <v-icon class="me-2" size="small" @click="editItem(item)" color='secondary'>
          $pencil
        </v-icon>
        <v-icon size="small" @click="deleteItem(item)" color='error'>
          $delete
        </v-icon>
      </template>
      -->

      <template v-slot:no-data>
        <div class='ma-6'>
          <v-btn color="primary" v-on:click="resetSearch">
            Reset
          </v-btn>
        </div>
      </template>


    </v-data-table-server>

    <!-- <pre class="text-caption">{{ serverItems }}</pre>-->

  </v-container>
</template>


<script lang='ts'>
import { Api } from '@/_api';
import CreateMembership from "@/components/Backoffice/CreateMembership.vue";
import EditMembership from "@/components/Backoffice/EditMembership.vue";
import { saveAs } from 'file-saver';

export default {
  name: 'backoffice-manage-memberships-view',
  components: { CreateMembership, EditMembership },
  data() {
    return {
      itemsPerPage: 50,
      headers: [
        { title: 'N', key: 'number', sortable: false, align: 'start' },
        { title: 'Nome', key: 'personalData.firstName', sortable: false, align: 'start' },
        { title: 'Cognome', key: 'personalData.lastName', sortable: false, align: 'start' },
        { title: 'Email', key: 'personalData.emailAddress', sortable: false, align: 'start' },
        { title: 'CF', key: 'personalData.taxId', sortable: false, align: 'start' },
        { title: 'Pagato', key: 'amount', sortable: false, align: 'end' },
        { title: 'Anno', key: 'validityYear', sortable: false, align: 'start' },
        //{ title: 'Azioni', key: 'actions', sortable: false, align: 'center' },
      ] as any, // my god -- vuetify ts bug?

      serverItems: Array<Api.MembershipDto>(),
      loading: true,
      totalItems: 0,
      query: '', // search query

      // create dialog membership
      dialogCreateMembership: false,
      // edit dialog membership
      dialogEditMembership: false,
      selectedMembership: Api.MembershipDto,
    }
  },
  methods: {
    createMembership(e) {
      console.log(e)
    },
    formatPrice(value: number | undefined) {
      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      if (value === undefined) return formatter.format(0);
      return formatter.format(value);
    },
    loadItems({ page, itemsPerPage, sortBy }) {
      if (!this.query) this.query = ''; // boh vediamo se va

      this.loading = true;

      // sortBy?
      this.$api.MembershipClient.getPaginatedMemberships(this.query, page, itemsPerPage).then(response => {
        if (!response.items) throw new Error("null items")

        this.serverItems = response.items;
        this.totalItems = response.totalCount;
        this.itemsPerPage = response.pageSize;
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    handleRowClick(e, data) {
      // data.item
      console.log('Row clicked: ', data.item);
      if (!data.item.id) {
        console.error('No item found: ', data.item);
        return;
      }

      this.selectedMembership = data.item;
      this.dialogEditMembership = true;
    },
    onSearch() {
      this.loadItems({ page: 1, itemsPerPage: this.itemsPerPage, sortBy: null });
    },
    resetSearch() {
      this.query = '';
      this.onSearch()
    },
    handleKeydown(e) {
      if (e.key === 'Escape') {
        this.dialogCreateMembership = false;
        this.dialogEditMembership = false;
      }
    },
    onCreated(membership: Api.MembershipDto) {
      this.serverItems = [...this.serverItems, membership];
    },
    onDeleted(membership: Api.MembershipDto) {
      this.serverItems = this.serverItems.filter(f => f.id !== membership.id);
      this.dialogEditMembership = false;
    },
    async exportMemberships() {
      const response = await this.$api.ExportClient.exportMemberships();

      if (!response.data) return;
      if (!response.headers) return;

      let filename = "download.xlsx";
      const contentDisposition = response.headers['content-disposition'];
      if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, ''); // Rimuovi eventuali apici che racchiudono il nome del file
        }
      }

      const blob = new Blob([response.data], { type: response.headers['content-type'] || 'application/octet-stream' });
      saveAs(blob, filename);
    }
  },
  created() {
    this.resetSearch();
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
  },
};
</script>
