<template>


  <MainAppBar :prop-drawer="drawer" @update-data="updateData" elevation="3">

    <!--
    <v-container class="mx-auto d-flex align-center justify-center">
      <v-btn v-for="link in links" :key="link" :text="link" variant="text"></v-btn>
      <v-spacer></v-spacer>
    </v-container>
-->
  </MainAppBar>


  <v-main>
    <!--<v-container>
      <router-view />
    </v-container>-->

    <div class="ma-auto">
      <!--
      <v-col cols="2">
        <v-sheet rounded="lg">
          <v-list rounded="lg">
            <v-list-item color="grey-lighten-4" title="Refresh" link></v-list-item>
            <v-list-item color="grey-lighten-4" title="Refresh" link></v-list-item>

            <v-divider class="my-2"></v-divider>

            <v-list-item color="grey-lighten-4" title="Refresh" link></v-list-item>
          </v-list>
        </v-sheet>
      </v-col>
-->

      <router-view v-slot="{ Component, route }">
        <v-container class="pa-0" :fluid="!!route.meta.fluid">
          <!-- min-height="70vh"  -->
          <v-sheet class='overflow-hidden' elevation="2">
            <!--<transition name="route">
              <component :is="Component" :key="`route_key_${route.path}`" />
            </transition>-->
            <component :is="Component" :key="`route_key_${route.path}`"/>
          </v-sheet>
        </v-container>
      </router-view>

      <!--
      <router-view v-slot=" { Component, route }">
            <transition name='fade'>
              <div class='router2' :key="route.path">
                <component :is="Component" />
              </div>
            </transition>
      </router-view>-->

    </div>

  </v-main>


  <v-footer app class="pa-0">
    <transition name="fade">
      <MainFooter v-if="showFooter"/>
    </transition>
  </v-footer>

</template>

<style scoped>
.route-leave-active,
.route-enter-active {
  transition: all 0.1s ease-out;
}

.route-enter-from {
  opacity: 0;
  transform: translateX(30px);
}

.route-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
</style>


<script lang='ts'>
import { useDisplay } from 'vuetify'

import MainAppBar from '@/components/MainAppBar.vue';
import MainFooter from '@/components/MainFooter.vue';

export default {
  components: {
    MainAppBar,
    MainFooter,
  },
  data() {
    return {
      drawer: Boolean(null),
      elevation: 0,
      links: [
        'Dashboard',
        'Profile',
      ],
      showFooter: false,
    };
  },
  methods: {
    updateData(newValue) {
      this.drawer = newValue;
    }
  },
  created() {
    const { width, mobile } = useDisplay()
    this.drawer = !mobile.value;
  },
  mounted() {
    this.$nextTick(() => {
      //this.$refs.footer.$el.classList.add('visible');
      this.showFooter = true;
    });
  }
}
</script>


<style scoped>
.translucid {
  background-color: rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: blur(11px) grayscale(30%);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.4s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

</style>
