import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/store/identityStore';

import NotFound from '../views/NotFound.vue'
import HelloWorld from '../components/HelloWorld.vue'
import Index from '../views/Index.vue'
import WebRequestView from '../views/WebRequest.vue'
import UsersIndexView from '../views/UsersIndexView.vue'
import TableServerAsync from '../views/TableServerAsync.vue'
import LoginView from '../views/User/LoginView.vue'
import LoginSequentialView from '../views/User/LoginSequentialView.vue'
import RegistrationView from '../views/User/RegistrationView.vue'
import ForgotPasswordView from '../views/User/PasswordForgotView.vue'
import PasswordResetView from '../views/User/PasswordResetView.vue'
import UserProfileView from '../views/User/UserProfileView.vue'
import HomePageView from '../views/HomePageView.vue'
import ScannerView from '../views/Backoffice/ScannerView.vue'
// STORE
import OrderBuilderView from '../views/Store/CartBuilderView.vue'
import OrdersIndexView from '../views/Store/OrdersIndexView.vue'
import TransferTicketView from '../views/Store/TransferTicketView.vue'
// BACKOFFICE
import ManageMembershipsView from '../views/Backoffice/ManageMembershipsView.vue'
// MANAGER
import ManageOrdersView from '../views/Backoffice/ManageOrdersView.vue'
import ManageSingleOrderView from '../views/Backoffice/ManageSingleOrderView.vue'


const defaultTitle = 'Alfero.net - Store';
const routes = [
  { path: '/scanner', name: 'scanner', component: ScannerView, meta: { title: 'QR Scanner', requiresAdmin: true, }, },
  {
    path: '/', name: 'root', component: Index, meta: { title: 'Pro Loco Alfero' },
    children: [
      { path: '', redirect: '/home', name: 'redirect-root' },  // Reindirizzamento da "/" a "/home"
      { path: 'home', name: 'home', component: HomePageView },
      //{ path: '/hello', component: HelloWorld },
      //{ path: '/web', name: 'web', component: WebRequestView },
      { path: '/users', name: 'users', component: UsersIndexView },
      { path: '/user/profile', name: 'user-profile', component: UserProfileView, meta: { requiresAuth: true } },
      { path: '/login', name: 'login', component: LoginSequentialView, meta: { title: 'Login' }, },
      { path: '/user/signup', name: 'signup', component: RegistrationView, meta: { title: 'Registrazione' }, },
      { path: '/user/forgot-password', name: 'forgot-password', component: ForgotPasswordView },
      { path: '/user/reset-password/:token?', name: 'reset-password', component: PasswordResetView },
      { path: '/table/async', name: 'table-async', component: TableServerAsync },
      {
        path: '/store/tickets',
        name: 'buy-tickets',
        component: OrderBuilderView,
        meta: { keepAlive: true, requiresAuth: true, requiresTaxId: true }
      },
      {
        path: '/store/orders',
        name: 'orders',
        component: OrdersIndexView,
        meta: { title: 'Storico Ordini', keepAlive: true, requiresAuth: true, }
      },
      {
        path: '/ticket/:ticketId?/transfer',
        name: 'transfer-ticket',
        component: TransferTicketView,
        meta: { title: 'Trasferimento Biglietto', keepAlive: true, requiresAuth: true, }
      },
      {
        path: '/backoffice/memberships',
        name: 'backoffice-memberships',
        component: ManageMembershipsView,
        meta: { title: 'Gestione Tessere', requiresAuth: true, requiresAdmin: true, fluid: true }
      },
      {
        path: '/backoffice/orders',
        name: 'backoffice-orders',
        component: ManageOrdersView,
        meta: { title: 'Gestione Ordini', keepAlive: true, requiresAuth: true, requiresAdmin: true, fluid: true }
      },
      {
        path: '/backoffice/order/:id?',
        name: 'backoffice-order',
        component: ManageSingleOrderView,
        meta: { title: 'Gestione Ordine', keepAlive: true, requiresAuth: true, requiresAdmin: true }
      },
    ]
  },
  { path: '/:pathMatch(.*)*', name: '404', component: NotFound },
]


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})


router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title + ' - Alfero.net';
  } else document.title = defaultTitle;

  const identityStore = useUserStore();

  if (to.meta.requiresAuth && !identityStore.isAuthenticated) {
    next({ name: 'login' });
  } else if (to.meta.requiresAdmin && !identityStore.isAdministrator) {
    next({ name: 'home' });
  } else if (to.meta.requiresTaxId && !identityStore.user.personalUserData.taxId && !identityStore.isAdministrator) {
    next({ name: 'user-profile' }); // plz compila le anagrafiche
  } else {
    next();
  }
});


export default router
