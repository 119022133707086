<template>
  <div class="pa-4">

    <h1 class="mb-4">Riepilogo ordini</h1>

    <div v-if="!items.length">

      <v-skeleton-loader v-if="firstLoading" type="article"></v-skeleton-loader>
      <div v-else>
        <div>Non è stato effettuato alcun ordine</div>
        <v-btn class="mt-6" color="primary" :to="{name: 'buy-tickets'}" prepend-icon="$cart">
          Acquista ora
        </v-btn>
      </div>
    </div>


    <div v-for="(item, index) in items" :key="`item_${index}`">
      <v-card class="mx-auto my-4" max-width="800" color="blue-lighten-5">
        <v-card-title>
          <v-row>
            <v-col>
              Ordine #{{ item.alphanumericId }}
              <v-chip density="comfortable" class="text-caption ma-1">
                {{ item.orderStatus }}
              </v-chip>

              <v-chip :color="paymentStatusColor(item.paymentStatus)" density="comfortable" class="text-caption ma-1"
                      v-if="item.orderStatus != 'Cancelled'">
                {{ item.paymentStatus }}
              </v-chip>

              <div class="pb-2 text-caption text-red-accent-4">
                <div v-if="item.ticketTransfer.id">
                  <b>
                    Richiesta di trasferimento da parte di
                    {{ item.ticketTransfer.petitionerUser.personalUserData.displayName }} </b>
                </div>

              </div>

              <div class="pb-2 text-caption">
                {{ formatDateTime(item.createdAt) }}
              </div>
            </v-col>

            <v-col align="end" v-if="item.paymentStatus == EnumPending">
              <v-btn color="green" :disabled="isLoading" class="ma-2" size="small" append-icon="$creditCard"
                     @click="onCheckoutOrder(item)"
                     v-if="item.orderStatus == 'Created' && item.paymentMethod == 'CreditCard' &&  item.paymentStatus == 'Pending'">
                Paga Ora
              </v-btn>
              <v-btn color="error" :disabled="isLoading" size="small" append-icon="$closeThick"
                     @click="onCancelOrder(item, index)"
                     v-if="item.orderStatus == 'Created' && item.paymentStatus == 'Pending'"
              >Annulla
              </v-btn>
            </v-col>

          </v-row>
        </v-card-title>

        <v-list dense bg-color="grey-lighten-5" class="pa-0 alternate-colors">
          <v-list-item
            v-for="product in item.orderProducts"
            :key="product.productId"
          >
            <!--
            <v-row>
              <v-col>{{ product.name }}</v-col>
              <v-col v-if="product.personalUserData">{{ product.personalUserData.displayName }}
                <span class="">{{ product.personalUserData.taxId }}</span>
              </v-col>
              <v-col cols="2" align-self="end" class="text-right">
                <v-chip class="text-blue"> {{ formatPrice(product.amount) }}</v-chip>
              </v-col>
            </v-row>
-->
            <v-row>
              <!-- Colonna A - Nome Prodotto -->
              <v-col cols="6" md="4">
                <div>{{ product.name }}</div>
                <div v-if="ticketCanBeExchanged(item, product)">
                  <v-btn density="comfortable" color="red"
                         :to="{ name: 'transfer-ticket'}">Info biglietto
                  </v-btn>
                </div>

              </v-col>

              <!-- Colonna B - Codice Fiscale, va sotto A e C su mobile -->
              <v-col cols="12" md="4" order-md="0" order="1">
                <div v-if="product.personalUserData">
                  <div>{{ product.personalUserData.displayName }}</div>
                  <span class="text-caption">{{ product.personalUserData.taxId }}</span>
                </div>
              </v-col>

              <!-- Colonna C - Prezzo -->
              <v-col cols="6" md="4" align-self="end" class="text-right">
                <v-chip class="text-blue"> {{ formatPrice(product.amount) }}</v-chip>
              </v-col>

            </v-row>

          </v-list-item>

        </v-list>

        <v-container>
          <v-row>
            <v-col>Totale</v-col>
            <v-col align-self="end" class="text-right ">
              <v-chip color="green" class="font-weight-bold"> {{ formatPrice(item.totalAmount) }}</v-chip>
            </v-col>
          </v-row>
        </v-container>

        <!--
        <v-card-actions>
          <v-btn color="blue darken-1" @click="showDetails">Details</v-btn>
        </v-card-actions>
        -->
      </v-card>

    </div>

    <!-- DIALOGS -->
    <v-dialog v-model="dialogPaymentCanceled" width="auto" scroll-strategy="close">
      <v-card max-width="400" color=indigo-300>
        <v-card-title class="bg-surface-light">Esito pagamento</v-card-title>
        <v-card-text>Il pagamento è stato annullato, puoi riprovare consultando l'ordine</v-card-text>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click="dialogPaymentCanceled = false">Ok</v-btn>
        </template>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialogPaymentPaid" width="auto" scroll-strategy="close">
      <v-card max-width="400">
        <v-card-title class="bg-surface-light">Esito pagamento</v-card-title>
        <v-card-text>Il pagamento è stato ricevuto verrà processato a breve</v-card-text>
        <template v-slot:actions>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="dialogPaymentPaid = false">Ok</v-btn>
        </template>
      </v-card>
    </v-dialog>

  </div>


</template>

<script setup lang='ts'>

</script>

<script lang='ts'>
const hardCodedTicketProductId = 'e9e198d5-6c40-4fb2-a653-e645fd369dca';


import { Api } from '@/_api';

import { TYPE, useToast } from 'vue-toastification';

const toast = useToast();
import { useCartStore } from '@/store/cartStorage';


export default {
  name: 'orders-index-view',
  data() {
    return {
      EnumPending: Api.PaymentStatus.Pending,
      firstLoading: true,
      items: Array<Api.PurchaseOrderDto>(),
      isLoading: false,
      dialogPaymentCanceled: false,
      dialogPaymentPaid: false,
    }
  },
  methods: {
    formatPrice(value: number | undefined) {
      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      if (value === undefined) return formatter.format(0);
      return formatter.format(value);
    },
    formatDateTime(date: Date) {
      return date.toLocaleDateString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        //second: '2-digit',
        hour12: false,
        timeZone: 'Europe/Rome'
      });
    },
    showDetails() {
      // Logica per mostrare i dettagli dell'utente e dei prodotti
      console.log('Showing details...');
    },
    paymentStatusColor(status) {
      if (Api.PaymentStatus.Paid == status) return 'green';
      return 'orange';
    },
    ticketCanBeExchanged(order, product) {
      if (order.orderStatus !== Api.OrderStatus.Completed) return false;
      if (order.paymentStatus !== Api.PaymentStatus.Paid) return false;
      if (product.productId !== hardCodedTicketProductId) return false;

      // TODO: verificare stato scambio ..?

      return true;
    },
    handleRowClick(e, data) {
      // data.item
      console.log('Row clicked: ', e, data);
    },
    onClick(e) {
      console.log('search click: ', e);
    },
    async onCheckoutOrder(item: Api.PurchaseOrderDto) {
      try {
        this.isLoading = true;
        let response = await this.$api.OrdersClient.checkoutOrderCC(item.id);
        if (!response.url) throw new Error("undefined checkout url");

        window.location.href = response.url;

      } catch {
        this.isLoading = false;
      }
    },
    async onCancelOrder(item: Api.PurchaseOrderDto, index: number) {
      this.isLoading = true;

      try {
        let response = await this.$api.OrdersClient.cancelOrder(item.id);
        if (!response) {
          toast("Errore annullamento ordine", { type: TYPE.ERROR })
          return;
        }

        this.items[index] = response;
        toast("Ordine annullato", { type: TYPE.SUCCESS })
      } catch {
        toast("Errore richiesta annullamento", { type: TYPE.ERROR })
      } finally {
        this.isLoading = false;
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    const cartStore = useCartStore();

    // Aggiorna la route e mostra il messaggio
    if (this.$route.query.newOrder) {

      if (this.$route.query.newOrder === 'canceled') {
        this.dialogPaymentCanceled = true;
      } else if (this.$route.query.newOrder === 'paid') {
        this.dialogPaymentPaid = true;
        cartStore.clearCart(); // cancello il carrello se l'esito è ok
      }

      // pulisco la url
      const query = { ...this.$route.query };
      delete query.newOrder;
      this.$router.replace({ path: this.$route.path, query }).catch(err => {
        if (err.name !== 'NavigationDuplicated')
          console.error('Router error', err);
      });
    }


    this.$api.OrdersClient.getMyOrders().then((response) => {
      if (response.items) this.items = response.items;
    }).catch(err => {
      console.error('OrdersClient', err);
    }).finally(() => {
      this.firstLoading = false;
    })
  },
  computed: {}
};
</script>


<style scoped>

.alternate-colors {
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
}

.alternate-colors > div.v-list-item:nth-child(odd) {
  background-color: rgba(100, 100, 100, 0.05);
  padding: 4mm;
}

</style>
