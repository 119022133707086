<template>
  <div>
    <div class="qrscan-container">
      <qrcode-stream @camera-on="onCameraOn" @camera-off="onCameraOff" @detect="onDetect"
                     :formats="['qr_code']"
                     :constraints="options.video"
                     :track="trackFunctionSelected.value"
                     :paused="paused"
                     class="qrdisplay"
                     :class="{'hidden': !!lastTicket?.ticket}"
      >
        <v-btn @click="switchCamera" color="white" icon="$cameraFlip" class="ma-3" :disabled="scannerLoading"></v-btn>

        <div
          class="loading-indicator text-center"
          v-if="scannerLoading"
        >
          <v-progress-circular size="150" color="green" v-if="scannerLoading" indeterminate></v-progress-circular>

        </div>

      </qrcode-stream>
    </div>

    <v-container class="ticketInfo" v-if="lastTicket && lastTicket.ticket">
      <!-- <h2>Info Biglietto</h2>-->

      <div>
        <div style="color:purple; font-size:1.4em"><strong>{{ lastTicket.ticket.name }}</strong></div>

        <div v-if="!lastTicket.ticket.enabled" style="color:red; font-size:1.5em"><strong>BIGLIETO NON ATTIVO</strong>
        </div>

        <div v-if="lastTicket.ticket.personalData.collaborator" style="color:blue"><strong>COLLABORATORE</strong>
        </div>

        <div v-if="lastTicket.ticket.signedAt" style="color:orangered">Timbrato il <strong>{{
            formatDateTime(lastTicket.ticket.signedAt)
          }}</strong></div>

        <div>Intestato a: <strong>{{ lastTicket.ticket.personalData.displayName }}

        </strong>
          <blockquote>
            <div>Data di nascita: <strong>{{ formatDate(lastTicket.ticket.personalData.birthDate) }}</strong></div>
            <div>CF: <strong>{{ lastTicket.ticket.personalData.taxId }}</strong></div>
            <div><strong>{{ lastTicket.ticket.personalData.emailAddress }}</strong></div>

          </blockquote>
        </div>

        <div style="color: darkgreen"> Pagato: <strong>{{ formatPrice(lastTicket.ticket.amount) }}</strong></div>

        <hr>
        <div>Acquistato il: <strong>{{ formatDate(lastTicket.ticket.purchaseDate) }}</strong></div>

        <small>
          ID: {{ lastTicket.ticket.id }}
        </small>


        <hr/>

        <div v-if="lastTicket.ticket.ticketRelations" style="font-size: .9em">
          <div v-for="(r, i) in lastTicket.ticket.ticketRelations" :key="r.id">
            {{ r.relationship }}: {{ r.user.personalUserData.displayName }}
          </div>
        </div>

      </div>

      <div class="fixed-sign-btn">
        <v-btn
          v-if="lastTicket?.ticket && !(lastTicket?.ticket?.signedAt) && lastTicket.ticket?.enabled"
          color="orange"
          size="x-large"
          rounded
          icon="$marker"
          :disabled="$api.isLoading"
          v-on:click="onSign(lastTicket.ticket.id)"
        >
        </v-btn>
      </div>

      <div class="fixed-scanner-btn">
        <v-btn
          v-if="lastTicket?.ticket"
          color="gray"
          size="x-large"
          rounded
          icon="$barcodeScan"
          :disabled="$api.isLoading"
          v-on:click="onNewScan"
        >
        </v-btn>
      </div>


    </v-container>

    <!--<pre>{{ lastTicket }}</pre>-->


  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'

function paintOutline(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

    ctx.strokeStyle = 'red'
    ctx.lineWidth = 4
    ctx.beginPath()
    ctx.moveTo(firstPoint.x, firstPoint.y)
    for (const { x, y } of otherPoints) {
      ctx.lineTo(x, y)
    }
    ctx.lineTo(firstPoint.x, firstPoint.y)
    ctx.closePath()
    ctx.stroke()
  }
}

function paintBoundingBox(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const {
      boundingBox: { x, y, width, height }
    } = detectedCode

    ctx.lineWidth = 2
    ctx.strokeStyle = '#007bff'
    ctx.strokeRect(x, y, width, height)
  }
}

function paintCenterText(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const { boundingBox, rawValue } = detectedCode

    const centerX = boundingBox.x + boundingBox.width / 2
    const centerY = boundingBox.y + boundingBox.height / 2

    const fontSize = Math.max(12, (50 * boundingBox.width) / ctx.canvas.width)

    ctx.font = `bold ${fontSize}px sans-serif`
    ctx.textAlign = 'center'

    ctx.lineWidth = 3
    ctx.strokeStyle = '#35495e'
    ctx.strokeText(detectedCode.rawValue, centerX, centerY)

    ctx.fillStyle = '#5cb984'
    ctx.fillText(rawValue, centerX, centerY)
  }
}

const trackFunctionOptions = [
  { text: 'nothing (default)', value: undefined },
  { text: 'outline', value: paintOutline },
  { text: 'centered text', value: paintCenterText },
  { text: 'bounding box', value: paintBoundingBox }
]
const trackFunctionSelected = ref(trackFunctionOptions[1])
</script>

<script lang='ts'>
import { Api } from '@/_api';

import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'

import { Howl } from 'howler';

import successFx from '@/assets/sounds/success.mp3';
import errorFx from '@/assets/sounds/error.mp3';
import warningFx from '@/assets/sounds/warning.wav';

const successSound = new Howl({ src: [successFx] });
const errorSound = new Howl({ src: [errorFx] });
const warningSound = new Howl({ src: [warningFx] });

import { TYPE, useToast } from 'vue-toastification';
import { DateTime } from "luxon";

const toast = useToast();

const ticketIdTest = '8c7ce25f-3389-49a4-b3e1-029c90ed30c0';

export default {
  name: "backoffice-scanner-ticket",
  components: {
    QrcodeStream,
    //QrcodeDropZone,
    //QrcodeCapture
  },
  data() {
    return {
      scannerLoading: true,
      paused: false,
      wakeLock: {} as WakeLockSentinel,
      options: {
        video: {
          facingMode: { ideal: 'environment' },
          //deviceId: { ideal: 'environment' },
          //width: {ideal: 4096 * 2},
          //height: {ideal: 4096 * 2},
          //aspectRatio: {ideal: 0.8},
          frameRate: { min: 20, ideal: 60 },
          focusMode: 'continuous',
          //imageStabilization: true,
        }
      },
      lastTicket: {} as Api.ScanResultDto | null,
    }
  },
  computed: {},
  methods: {
    async onCameraOn(capabilities: Promise<MediaTrackCapabilities>) {
      console.log('Camera ON', capabilities);
      this.scannerLoading = false;
    },
    formatPrice(value: number | undefined) {
      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      if (value === undefined) return formatter.format(0);
      return formatter.format(value);
    },
    formatDate(date: Date) {
      if (!date) return undefined;
      const dt = DateTime.fromJSDate(date);
      return dt.toFormat('yyyy-MM-dd');
    },
    formatDateTime(date: Date) {
      if (!date) return undefined;
      const dt = DateTime.fromJSDate(date);
      return dt.toFormat('yyyy-MM-dd HH:mm');
    },
    onCameraOff() {
      console.log('Camera OFF')
    },
    switchCamera() {
      this.scannerLoading = true;
      switch (this.options.video.facingMode.ideal) {
        case 'environment':
          this.options.video.facingMode.ideal = 'user'
          break
        case 'user':
          this.options.video.facingMode.ideal = 'environment'
          break
      }
    },
    onError(error: Error) {
      console.error(error)
      let message = '';

      if (error.name === 'NotAllowedError') {
        message += 'you need to grant camera access permission'
      } else if (error.name === 'NotFoundError') {
        message += 'no camera on this device'
      } else if (error.name === 'NotSupportedError') {
        message += 'secure context required (HTTPS, localhost)'
      } else if (error.name === 'NotReadableError') {
        message += 'is the camera already in use?'
      } else if (error.name === 'OverconstrainedError') {
        message += 'installed cameras are not suitable'
      } else if (error.name === 'StreamApiNotSupportedError') {
        message += 'Stream API is not supported in this browser'
      } else if (error.name === 'InsecureContextError') {
        message +=
          'Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.'
      } else {
        message += error.message
      }
    },
    async onDetect(detectedCodes) {
      console.log(detectedCodes);
      const first = detectedCodes[0];
      const code = first.rawValue;

      await this.checkTicket(code);
    },

    async checkTicket(code: string) {
      try {
        this.paused = true;
        this.scannerLoading = true;

        this.lastTicket = await this.$api.TicketsClient.checkTicket(code);

        if (this.lastTicket.ticket?.signedAt)
          warningSound.play();
        if (!this.lastTicket.ticket?.enabled)
          warningSound.play();
      } catch (e) {
        this.paused = false;
        errorSound.play();
        console.error(e);
        //toast(e.description, { type: TYPE.ERROR })
      }
    },

    async onSign(code: string) {
      try {
        await this.$api.TicketsClient.signTicket(code);
        this.lastTicket = null;
        toast("Timbrato", { type: TYPE.SUCCESS })

      } catch (e) {
        console.error(e);

        errorSound.play();
        await this.checkTicket(code);
      } finally {
        this.scannerLoading = true;
        this.paused = false;
      }
    },
    onNewScan() {
      this.lastTicket = null;
      this.scannerLoading = true;
      this.paused = false;
    }
  },
  async mounted() {
    //await this.checkTicket(ticketIdTest);
  },
  async created() {
    try {
      this.wakeLock = await navigator.wakeLock.request('screen');
      console.log(this.wakeLock)
    } catch (err) {
      console.error(err);
    }
  },
  beforeUnmount() {
    if (this.wakeLock) {
      this.wakeLock.release();
    }
  },
};
</script>


<style scoped>

.qrscan-container {
}

.qrdisplay {
  background: #000;
}

.qrdisplay.hidden {
  display: none;
}


.ticketInfo {
  font-size: 1.2em;
}


.fixed-sign-btn {
  position: fixed;
  bottom: 4cm;
  right: 2cm;
  z-index: 1000;
}


.fixed-scanner-btn {
  position: fixed;
  bottom: 7cm;
  right: 2cm;
  z-index: 1000;
}


</style>
