/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import vuetify from './vuetify'
import pinia from '../store'
import router from '../router/index.classic'
import toast from "vue-toastification";
import toastOptions from './toast'
import httpService from './httpService'
//import VueApexCharts from "vue3-apexcharts";
import * as Sentry from "@sentry/vue";

// Types
import type { App } from 'vue'

export function registerPlugins(app: App) {
  app
    .use(vuetify)
    .use(router)
    .use(pinia)
    .use(toast, toastOptions)
    .use(httpService, { baseUrl: import.meta.env.VITE_BASE_API_URL })
//    .use(VueApexCharts) // make <apexchart> component available everywhere

  Sentry.init({
    app,
    dsn: "https://14b53e2a6847db3fdaa2cf70a2c5ade2@o260480.ingest.us.sentry.io/4507162836860928",
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
