import { Api } from '@/_api';
import { defineStore } from 'pinia'
import { getApi } from '@/plugins/httpService';
import { useCartStore } from '@/store/cartStorage';

interface UserState {
  user: Api.ApplicationUserDto;
}

//const cryptLSKey = 'not so secret key';

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    user: new Api.ApplicationUserDto({
      id: '',
      username: 'Ospite',
      personalUserData: new Api.PersonalUserData(),
      roles: []
    })
  }),
  getters: {
    displayName: (state) => {
      if (!state.user.personalUserData) return null;
      return `${state.user.personalUserData.firstName || ''} ${state.user.personalUserData.lastName || ''}`.trim() || `${state.user.id}`;
    },
    getInitials: (state) => {
      const { firstName, lastName } = state.user.personalUserData!;
      return `${firstName?.charAt(0)}${lastName?.charAt(0)}`.trim().toUpperCase();
    },
    isAdministrator: (state) => {
      if (!state.user || state.user.roles == undefined) return false;
      return state.user.roles.some(role => role.toLowerCase() === 'administrator');
    },
    isAuthenticated: (state) => {
      return !!state.user?.id;
    },
  },
  actions: {
    setUser(userData: Api.ApplicationUserDto) {
      this.user = userData;
    },
    async logout() {
      try {
        this.$reset();
        this.$persist();

        const api = getApi();
        await api.AccountClient.logout();

      } catch (error) {
        console.error("Logout failed", error);
      } finally {

        //this.$router.replace({ name: 'login' });
        //location.reload();
      }
    }
  },
  persist: {
    key: 'user',
    storage: localStorage,
  },
})

export default useUserStore;
