import { defineStore } from 'pinia';
import { Api } from '@/_api';

interface CartState {
  items: Api.ITicketProducts[];
}

export const useCartStore = defineStore('cart', {
  // Definizione dello stato con il tipo appropriato
  state: (): CartState => ({
    items: Array<Api.ITicketProducts>(), // Array di Api.ITicket_CreateOrder_Request, adatto per un carrello
  }),
  getters: {
    getItems: (state) => state.items,
  },
  actions: {
    setItems(item: Api.ITicketProducts[]) {
      this.items = item;
    },
    addItem(item: Api.ITicketProducts) {
      this.items.push(item);
    },
    clearCart() {
      this.$reset();
      this.$persist();
    },
  },
  persist: {
    key: 'cart-store',
    storage: localStorage,
  },
});

export default useCartStore;
