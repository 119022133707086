<template>
  <v-row justify="center" align="center">
    <v-col cols="12" sm="8" md="8" lg="8">

      <v-card elevation="0">

        <v-form v-model="formValid" @submit.prevent="onSubmit" :disabled='$api.isLoading'>
          <div class="text-center mb-5">
            <h1>Crea un nuovo account</h1>
            <div class="text-md-body-2">È veloce e semplice</div>
          </div>

          <v-card-item>
            <v-row>
              <v-col md="6">
                <!-- NOME -->
                <v-text-field v-model="user.firstName" color="primary" name="firstName" autocomplete="first_name"
                              type="email"
                              :rules="[rules.required]" :readonly='$api.isLoading' placeholder='Nome'
                              variant='outlined'
                              bg-color='white'>
                </v-text-field>
              </v-col>

              <v-col md="6">
                <!-- COGNOME -->
                <v-text-field v-model="user.lastName" color="primary" name="lastName" autocomplete="family-name"
                              type="text"
                              :rules="[rules.required]"
                              :readonly='$api.isLoading' placeholder='Cognome' variant='outlined'
                              bg-color='white'
                >
                </v-text-field>
              </v-col>

            </v-row>


            <!-- USERNAME/EMAIL -->
            <v-text-field v-model="user.email" color="primary" name="email" type="email" :rules="[rules.required]"
                          :readonly='$api.isLoading' clearable autocomplete='username' class="mb-2"
                          placeholder='Indirizzo email'
                          variant='outlined' bg-color='white' prepend-inner-icon="$at">
            </v-text-field>

            <!-- WOW un date picker nativo che funziona -->
            <!-- WOW un date picker nativo che funziona -->
            <v-text-field label="Data di nascita" type="date"
                          ref="dateField"
                          v-model="formattedDate"
                          placeholder="Data di nascita"
                          v-on:click:append-inner="triggerNativePicker"
                          :rules="[rules.required, rules.validDate]"
                          append-inner-icon="$calendar"
                          :min="minDate"
                          :max="maxDate"
            ></v-text-field>

            <!-- PHONE -->
            <v-text-field v-model="user.primaryPhone" color="primary" name="phone" type="text"
                          :rules="[rules.required]"
                          :readonly='$api.isLoading' autocomplete='phone' class="mb-2" placeholder='Cellulare'
                          variant='outlined'
                          bg-color='white' prepend-inner-icon="$phone">
            </v-text-field>


            <!-- PASSWORD -->
            <v-text-field v-model="user.password" color="primary" name="password"
                          :type="visible ? 'text' : 'password'"
                          :rules="[rules.required]" :readonly='$api.isLoading' autocomplete='new-password'
                          class="mb-2"
                          :append-inner-icon="visible ? '$eye-off' : '$eye'" @click:append-inner="visible = !visible"
                          placeholder='Crea una password' variant='outlined' bg-color='white'
                          prepend-inner-icon='$lock'>
            </v-text-field>

            <!-- PASSWORD CONFIRMATION -->
            <v-text-field v-model="user.passwordConfirm" color="primary" name="passwordConfirm"
                          :type="visibleConfirm ? 'text' : 'password'" :rules="[rules.required, rules.passwordMatch]"
                          :readonly='$api.isLoading' autocomplete='new-password' class="mb-2"
                          :append-inner-icon="visibleConfirm ? '$eye-off' : '$eye'"
                          @click:append-inner="visibleConfirm = !visibleConfirm" placeholder='Conferma la password'
                          variant='outlined'
                          bg-color='white' prepend-inner-icon='$lock'>
            </v-text-field>

            <v-btn type="submit" color="secondary" size="large" class="mt-4" :loading='$api.isLoading && formValid'
                   :disabled='!formValid' block rounded>Iscriviti
            </v-btn>
          </v-card-item>

          <v-card-actions>
            <v-btn v-on:click="$emit('signin')" color="primary" block rounded> <!-- :to="{ name: 'login' }" -->
              Hai già un account?
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>


<script lang='ts'>

import { Api } from '@/_api';
import { useUserStore } from '@/store/identityStore';

import { TYPE, useToast } from 'vue-toastification';
import { DateTime } from "luxon";

const toast = useToast()

export default {
  name: "user-signup",
  props: {
    email: {
      type: String,
      required: false,
      default: null,
    }
  },
  emit: ['signin'],
  data() {
    return {
      formValid: false,
      visible: false,
      visibleConfirm: false,
      user: {
        firstName: undefined,
        lastName: undefined,
        email: this.email,
        password: undefined,
        passwordConfirm: undefined,
        primaryPhone: undefined,
        birthDate: new Date(),
      },
    }
  },
  created() {
    let now = DateTime.now();
    let twentyYearsAgo = now.minus({ years: 20 });
    this.user.birthDate = twentyYearsAgo.toJSDate();
  },
  computed: {
    rules() {
      return {
        required: value => !!value || 'Campo obbligatorio',
        passwordMatch: () => this.user.password === this.user.passwordConfirm || 'Le password non corrispondono',
        validDate: value => (DateTime.fromISO(value).isValid) || 'Data di nascita non valida',
      }
    },
    formattedDate: {
      get() {
        const date = this.user.birthDate;
        if (!date) return undefined;

        const dt = DateTime.fromJSDate(date);
        const f = dt.toFormat('yyyy-MM-dd');

        return f
      },
      set(newValue) {
        this.user.birthDate = new Date(newValue);
      }
    },
    maxDate() {
      return DateTime.now().toISODate();
    },
    minDate() {
      return DateTime.fromISO('1910-01-01').toISODate();
    },
  },
  methods: {
    triggerNativePicker() {
      const dateInput = this.$refs.dateField as HTMLInputElement;
      dateInput.showPicker();
    },
    onSubmit() {
      const vm = this;
      const identityStore = useUserStore();

      let dto = Api.UserRegistrationDto.fromJS(this.user)!;

      this.$api.AccountClient.register(dto).then((response) => {
        console.log('registration ok', response.user)

        identityStore.setUser(response.user);

        if (!response.user.personalUserData.taxId) {
          vm.$router.push({ name: 'user-profile' });


        } else {
          vm.$router.push({ name: response.redirect?.name });
        }
      }).catch((error) => {
        console.error('registration failed', error);
      });
    },
  },
}
</script>
