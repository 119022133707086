<template>
  <v-container class="fill-height" fluid>
    <v-row justify="center" align="center" no-gutters>
      <v-col cols="12" sm="8" md="6" lg="6">
        <v-card class="mx-auto mt-5" elevation="6">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>Reset Password</v-toolbar-title>
          </v-toolbar>


          <v-form @submit.prevent="onSubmit" v-model="formValid">
            <v-card-item>

              <div class='text-medium-emphasis mb-3'>
                Inserisci la nuova password
              </div>

              <!-- PASSWORD -->
              <v-text-field v-model="user.password" color="primary" name="password"
                            :type="visible ? 'text' : 'password'"
                            :rules="[rules.required]" :readonly='$api.isLoading' autocomplete='new-password'
                            class="mb-2"
                            :append-inner-icon="visible ? '$eye-off' : '$eye'" @click:append-inner="visible = !visible"
                            placeholder='Crea una password' variant='outlined' bg-color='white'
                            prepend-inner-icon='$lock'>
              </v-text-field>

              <!-- PASSWORD CONFIRMATION -->
              <v-text-field v-model="user.passwordConfirm" color="primary" name="passwordConfirm"
                            :type="visible ? 'text' : 'password'" :rules="[rules.required, rules.passwordMatch]"
                            :readonly='$api.isLoading' autocomplete='new-password' class="mb-2"
                            :append-inner-icon="visible ? '$eye-off' : '$eye'"
                            @click:append-inner="visible = !visible" placeholder='Conferma la password'
                            variant='outlined'
                            bg-color='white' prepend-inner-icon='$lock'>
              </v-text-field>

              <!-- Submit Button -->
              <v-btn type="submit" color="primary" size="large" class='mt-4' block
                     :disabled="!formValid || $api.isLoading"
                     :loading='$api.isLoading'>
                Reimposta Password
              </v-btn>
            </v-card-item>

          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang='ts'>
import { Api } from '@/_api';
import { ref } from 'vue';

// Definizione degli eventi
const emit = defineEmits(['reset-request']);

const submitResetRequest = () => {
  // Qui puoi aggiungere la logica per la richiesta di reset della password

};
</script>

<script lang='ts'>

import { TYPE, useToast } from 'vue-toastification';
import useUserStore from "@/store/identityStore";

const toast = useToast()

export default {
  name: "user-forgot-password",
  data() {
    return {
      formValid: false,
      visible: false,
      user: { token: '', email: '', password: '', passwordConfirm: '' },
    }
  },
  computed: {
    rules() {
      return {
        required: value => !!value || 'Campo obbligatorio',
        passwordMatch: () => this.user.password === this.user.passwordConfirm || 'Le password non corrispondono',
      }
    }
  },
  methods: {
    async onSubmit() {
      if (this.$api.isLoading) return;

      let dto = new Api.ResetPasswordRequest({
        email: this.user.email,
        newPassword: this.user.password,
        token: this.user.token
      })!;

      try {
        await this.$api.AccountClient.resetPassword(dto);

        toast("Password resettata correttamente", { type: TYPE.SUCCESS });
        await new Promise(resolve => setTimeout(resolve, 200));

        await this.login();

      } catch {

        toast("Errore reset password", { type: TYPE.ERROR });
      }

      //this.$emit('reset-request', email.value);
    },
    async login() {
      const identityStore = useUserStore();

      const dto = new Api.LoginRequestDto();
      dto.email = this.user.email
      dto.password = this.user.password
      dto.rememberMe = true

      let response = await this.$api.AccountClient.loginPOST(dto);

      identityStore.setUser(response.user);
      this.$router.push({ name: response.redirect?.name });
    },
  },
  created() {
    if (typeof this.$route.query.token === 'string')
      this.user.token = this.$route.query.token;
    if (typeof this.$route.query.email === 'string')
      this.user.email = this.$route.query.email;

    if (!this.user.token) toast("Token non valido", { type: TYPE.ERROR });
    if (!this.user.email) toast("Email non valida", { type: TYPE.ERROR });

    //this.$router.replace({
    //  path: this.$route.path,
    //  query: {}  // Passando un oggetto vuoto, i query parameters verranno rimossi.
    //});
  }
}
</script>
