<template>
  <PasswordForgot></PasswordForgot>
</template>

<script setup lang='ts'>

import PasswordForgot from '@/components/User/PasswordForgot.vue';

</script>

<script lang='ts'>
import { ref } from 'vue';

export default {
  name: "login-view",
  components: {
    PasswordForgot
  },
  data() {
    return {}
  },
  methods: {},
  created() {
  },
  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>
