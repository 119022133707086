<template>

  <v-container fluid>

    <div>
      <v-btn color="orange" :to='{ name: "buy-tickets" }'>
        <template v-slot:prepend>
          <v-icon color='orange'>$plus</v-icon>
        </template>
        <span>Aggiungi ordine</span>
      </v-btn>
    </div>


    <div class="mt-5">
      <v-btn color="secondary" v-on:click="exportOnlinePaidOrders">
        <template v-slot:prepend>
          <v-icon>$export</v-icon>
        </template>
        <span>Esporta dettagli ordini pagati online</span>
      </v-btn>

    </div>

    <v-text-field density="comfortable"
                  class="ma-6" v-model="query" variant='outlined' color='primary'
                  label="Cerca per nome, cognome, codice fiscale, email..." hide-details single-line
                  append-inner-icon="$magnify"
                  @keydown.enter="onSearch"
                  @click:append-inner="onSearch"
    ></v-text-field>

    <!-- clearable @click:append-inner="onSearch" TOFIX: bug null native se premi clear -->

    <v-data-table-server density="compact" v-model:items-per-page="itemsPerPage" :headers="headers" :items="serverItems"
                         :items-length="totalItems" :loading="loading" item-value="id"
                         @update:options="loadItems"
                         v-model:expanded="expanded"
    >
      <!--@click:row="handleRowClick"-->

      <template v-slot:item.createdAt="{ item }">
        <span>{{ formatDateDM(item.createdAt) }}
          <v-tooltip
            activator="parent"
            location="end"
          >{{ formatDateTime(item.createdAt) }}
          </v-tooltip>
        </span>
      </template>


      <template v-slot:item.user.personalData.birthDate="{ item }">
        {{ formatDate(item.user.personalData.birthDate) }}
      </template>


      <template v-slot:item.totalAmount="{ item }">
        {{ formatPrice(item.totalAmount) }}
      </template>

      <template v-slot:item.paymentMethod="{ item }">
        <v-select v-model="item.paymentMethod" :items="paymentMethodOptions" hide-details
                  variant="plain" density="compact"></v-select>
      </template>


      <template v-slot:item.orderStatus="{ item }">
        <v-select v-model="item.orderStatus" :items="orderStatusOptions" hide-details
                  variant="plain" density="compact"></v-select>
      </template>

      <template v-slot:item.paymentStatus="{ item }">
        <v-select v-model="item.paymentStatus" :items="paymentStatusOptions" hide-details
                  variant="plain" density="compact"></v-select>
      </template>

      <template v-slot:item.actions="{ item }">

        <v-btn color="primary" density="compact" class="text-caption" v-on:click="onSaveRow(item)" :disabled="loading">
          Salva
        </v-btn>
        <!--
        <v-icon class="me-2" size="small" @click="editItem(item)" color='secondary'>
          $pencil
        </v-icon>
        <v-icon size="small" @click="deleteItem(item)" color='error'>
          $delete
        </v-icon>
        -->
      </template>

      <!-- EXPANSION PANEL -->
      <template v-slot:expanded-row="{ item, columns }">
        <tr>
          <td :colspan="columns.length">
            <v-row class="ma-4 mt-1 mb-1" v-for="(op, index) in item.orderProducts" :key="`op_id_${op.id}`">
              <v-col>{{ op.name }}</v-col>
              <v-col>{{ op.personalUserData.displayName }}</v-col>
              <v-col>{{ formatDate(op.personalUserData.birthDate) }}</v-col>
              <v-col>{{ op.personalUserData.taxId }}</v-col>
              <v-col>{{ op.personalUserData.emailAddress }}</v-col>
              <v-col>{{ formatPrice(op.amount) }}</v-col>
            </v-row>
          </td>
        </tr>
      </template>


      <template v-slot:no-data>
        <div class='ma-6'>
          <v-btn color="primary">
            Reset
          </v-btn>
        </div>
      </template>


      <template v-slot:tfoot>
        <div class='ma-8'></div>
      </template>


    </v-data-table-server>
  </v-container>
</template>


<script lang='ts'>
import { Api } from '@/_api';
import { DateTime } from "luxon";
import { TYPE, useToast } from 'vue-toastification';
import { saveAs } from 'file-saver';

const toast = useToast();


export default {
  name: 'backoffice-manage-orders-view',
  data() {
    return {
      expanded: [], // expanded rows
      itemsPerPage: 50,
      headers: [
        { title: 'Data', key: 'createdAt', align: 'start', sortable: false },
        { title: '#', key: 'alphanumericId', align: 'start', sortable: false },
        { title: 'Capogruppo', key: 'user.personalData.displayName', align: 'start', sortable: false },
        //{ title: 'CF', key: 'user.personalData.taxId', align: 'start', sortable: false },
        { title: 'Email', key: 'user.email', align: 'start', sortable: false },
        //{ title: 'Anno', key: 'user.personalData.birthDate', align: 'start', sortable: false },
        { title: 'Pagato', key: 'totalAmount', align: 'end', sortable: false },
        { title: 'Stato', key: 'orderStatus', sortable: false },
        { title: 'Pagamento', key: 'paymentStatus', sortable: false },
        { title: 'Metodo', key: 'paymentMethod', sortable: false },
        { title: '', key: 'actions', sortable: false },
        { title: '', key: 'data-table-expand' },
      ] as any, // my god -- vuetify ts bug?,
      serverItems: Array<Api.PurchaseOrderDto>(),
      loading: true,
      totalItems: 0,
      query: '', // search query

      // available options
      paymentMethodOptions: Object.values(Api.PaymentMethod),
      paymentStatusOptions: Object.values(Api.PaymentStatus),
      orderStatusOptions: Object.values(Api.OrderStatus),
    }
  },
  methods: {
    formatPrice(value: number | undefined) {
      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      if (value === undefined) return formatter.format(0);
      return formatter.format(value);
    },
    formatDateTime(date: Date) {
      if (!date) return undefined;
      const dt = DateTime.fromJSDate(date);
      return dt.toFormat('yyyy-MM-dd HH:mm:ss');
    },
    formatDate(date: Date) {
      if (!date) return undefined;
      const dt = DateTime.fromJSDate(date);
      const f = dt.toFormat('yyyy-MM-dd');
      return f
    },
    formatDateDM(date: Date) {
      if (!date) return undefined;
      const dt = DateTime.fromJSDate(date);
      const f = dt.toFormat('MM-dd');
      return f
    },
    loadItems({ page, itemsPerPage, sortBy }) {
      this.loading = true;

      // sortBy?
      this.$api.OrdersClient.searchOrders(this.query, page, itemsPerPage,).then(response => {
        if (!response.items) throw new Error("null items")

        this.serverItems = response.items;
        this.totalItems = response.totalCount;
        this.itemsPerPage = response.pageSize;

      }).catch(error => {
        //toast(error, { type: TYPE.ERROR })
        console.error(error);
      }).finally(() => {
        this.loading = false;
      });
    },
    async onSaveRow(item: Api.PurchaseOrderDto) {
      console.log('cl', item)
      //this.loading = true;

      let dto = new Api.UpdatePurchaseOrder_Request();

      dto.id = item.id;
      dto.orderStatus = item.orderStatus;
      dto.paymentMethod = item.paymentMethod;
      dto.paymentStatus = item.paymentStatus;

      try {
        let result = await this.$api.OrdersClient.updateOrder(item.id, dto);
        toast("Ordine aggiornato", { type: TYPE.SUCCESS })
        item = result;

      } catch (error) {
        toast("Errore aggiornamento: " + error, { type: TYPE.ERROR })
      } finally {
        //this.loading = false;
      }
    },
    handleRowClick(e, data) {
      //console.log('Row clicked: ', e, data);
    },
    onSearch() {
      this.loadItems({ page: 1, itemsPerPage: this.itemsPerPage, sortBy: null });
    },
    resetSearch() {
      this.query = '';
      this.onSearch()
    },
    async exportOnlinePaidOrders() {
      const response = await this.$api.ExportClient.exportOnlinePaidOrders();

      if (!response.data) return;
      if (!response.headers) return;

      let filename = "download.xlsx";
      const contentDisposition = response.headers['content-disposition'];
      if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, ''); // Rimuovi eventuali apici che racchiudono il nome del file
        }
      }

      const blob = new Blob([response.data], { type: response.headers['content-type'] || 'application/octet-stream' });
      saveAs(blob, filename);

      //const fileURL = window.URL.createObjectURL(result.data)
      //window.open(fileURL)
    }
  },
  created() {
    this.resetSearch();
  },
};
</script>
