<template>
  <h2 class=' mb-4'>Cambio Password</h2>

  <v-form v-model="formValid" @submit.prevent="submitPasswordReset" :disabled='isLoading'>
    <!-- PASSWORD -->
    <v-text-field v-model="password" color="primary" name="password" :type="visible ? 'text' : 'password'"
                  :rules="[rules.required]" :readonly='isLoading' autocomplete='new-password' class="mt-2"
                  :append-inner-icon="visible ? '$eye-off' : '$eye'" @click:append-inner="toggleVisibility"
                  placeholder='Crea una password' variant='outlined' bg-color='white' prepend-inner-icon='$lock'
                  :error-messages='passwordErrors'>
    </v-text-field>


    <!-- PASSWORD CONFIRMATION -->
    <v-text-field v-model="passwordConfirm" color="primary" name="passwordConfirm" :type="visible ? 'text' : 'password'"
                  :rules="[rules.passwordMatch]" :readonly='isLoading' autocomplete='new-password' class="mb-2"
                  :append-inner-icon="visible ? '$eye-off' : '$eye'" @click:append-inner="toggleVisibility"
                  placeholder='Conferma la password' variant='outlined' bg-color='white' prepend-inner-icon='$lock'>
    </v-text-field>

    <v-btn type="submit" color="error" :loading='isLoading && formValid'
           :disabled='!formValid || isLoading'>Cambia
      password
    </v-btn>
  </v-form>
</template>

<script>
import { ref } from 'vue';
import { Api } from '@/_api';

import { TYPE, useToast } from 'vue-toastification';

const toast = useToast();

export default {
  name: 'user-password-reset',
  data() {
    return {
      formValid: true,
      visible: false,
      password: '',
      passwordConfirm: '',
      isLoading: false,
      passwordErrors: [],
    };
  },
  computed: {
    rules() {
      return {
        required: value => !!value || 'Campo obbligatorio',
        passwordMatch: () => this.password === this.passwordConfirm || 'Le password non corrispondono',
      };
    },
  },
  methods: {
    toggleVisibility() {
      this.visible = !this.visible;
    },
    submitPasswordReset() {
      // var dto = Api.UpdatePassword_Request.fromJS({
      //   password: this.password,
      // });

      //const updatePasswordRequest = new Api.UpdatePassword_Request()
      //updatePasswordRequest.password = "paawwwsss";

      this.isLoading = true;
      this.$http.post('/api/user/password/update', { password: this.password }).then((response) => {
        console.log(response);
        toast('Password aggiornata', { type: TYPE.SUCCESS });
      }).catch((err) => {
        console.warn(err.response);
        console.warn('data', err.response.data);
      }).finally(() => {
        this.isLoading = false;
      });
    },
  },
};
</script>
