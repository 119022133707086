<template>

  <v-card-title>
    Modifica Tessera
  </v-card-title>

  <v-container>

    <!-- Anagrafiche -->
    <v-form ref='formpd' class='mb-10' v-model="formValid" @submit.prevent="submitPersonalDataForm"
            :disabled='isSavingPD'>

      <PersonalUserDataForm v-model="localMembership.personalData"/>

      <v-row>
        <v-col cols="4">
          <v-text-field
            v-model.number="localMembership.amount"
            label="Importo in Euro"
            prefix="€"
            density="comfortable"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            type="number"
            v-model.number="localMembership.number"
            label="Numerazione"
            density="comfortable"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn type="submit" color="secondary" :loading='isSavingPD && formValid'>
            Aggiorna Tessera
          </v-btn>
        </v-col>

        <v-col>
          <v-btn color="error" :loading='isSavingPD' v-on:click="deleteMembership">
            Elimina Tessera
          </v-btn>
        </v-col>
      </v-row>


    </v-form>

    <!-- NO: lista di relazioni perché questo è il builder? magari + avanti -->

  </v-container>

  <pre class="text-caption">{{ modelValue }}</pre><!---->
</template>


<script lang='ts'>
import { Api } from '@/_api';
import PersonalUserDataForm from '@/components/Store/PersonalUserDataForm.vue';
import { TYPE, useToast } from 'vue-toastification';

const toast = useToast();

export default {
  name: "create-membership-component",
  component: { PersonalUserDataForm },
  emits: {
    'update:modelValue': (value: Api.MembershipDto) => true,
    'deleted': (value: Api.MembershipDto) => value && value.id.length > 0,
  },
  props: {
    modelValue: {
      type: Object as () => Api.MembershipDto,
      required: true
    },
  },
  computed: {
    membership: {
      get() {
        return this.modelValue
      },
      set(value: Api.MembershipDto) {
        this.$emit('update:modelValue', value)
      }
    },
    formPD() {
      return this.$refs.formpd as HTMLFormElement
    }
  },
  data() {
    return {
      localMembership: { ...this.modelValue },
      isSavingPD: false, // salvataggio personal data
      formValid: false,
      //personalUserData: {} as Api.PersonalUserData,

      // search bar
      selectedUser: null as Api.ApplicationUserDto | null,
      items: Array<Api.ApplicationUserDto>(),
      search: null,
      isLoadingUsers: false, // caricamento ricerca utenti

      // costo tessera
      //amount: 10,
    }
  },
  methods: {
    async submitPersonalDataForm(e) {
      const { valid } = await this.formPD.validate();
      if (!valid) {
        toast("Compila correttamente il form", { type: TYPE.ERROR });
        return
      }
      if (!this.formValid) return;

      this.isSavingPD = true;

      let dto = new Api.UpdateMembership();
      dto.id = this.localMembership.id;
      dto.personalUserData = new Api.PersonalUserData(this.localMembership.personalData);
      dto.amount = this.localMembership.amount;
      dto.number = this.localMembership.number;

      this.$api.MembershipClient.updateMembership(this.localMembership.id, dto)
        .then(response => {
          toast("Tessera creata correttamente: " + response.id, { type: TYPE.SUCCESS });
          //this.$emit('update:modelValue', { ...this.localMembership });
        })
        .catch(error => {
          console.error("Errore creazione tessera:", error);
          toast(error, { type: TYPE.ERROR });
        })
        .finally(() => {
          this.isSavingPD = false;
        });
    },
    onInput(search: string) {
      if (!search) return;

      this.isLoadingUsers = true;
      this.$api.UserClient.searchUsers(search)
        .then(response => {
          this.items = response;
        })
        .catch(error => {
          console.error("Errore nella ricerca:", error);
        })
        .finally(() => {
          this.isLoadingUsers = false;
        });
    },
    async deleteMembership(e: Event) {
      let success = await this.$api.MembershipClient.deleteMembership(this.localMembership.id);
      if (success) {
        toast("Tessera eliminata", { type: TYPE.SUCCESS });
        this.$emit('deleted', this.modelValue)
      } else {
        toast("Errore eliminazione", { type: TYPE.ERROR });
      }
    }
  },
  mounted() {
  },
};
</script>
