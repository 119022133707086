<template>
  <v-card flat color='blue-grey-lighten-4' rounded="0">
    <!--<v-app-bar flat color="transparent animated-gradient" class='translucent' :elevation="elevation">-->

    <template v-slot:title>
      <div class="d-flex align-center">
        <v-img
          alt="Pro Loco Alfero"
          src="@/assets/4x3bevi_min.png"
          max-height="100%"
          max-width="120"
          min-width="60"
          class="mr-2"
        ></v-img>
        <span>{{ $route.meta.title }}</span>
      </div>
    </template>


    <!--
    <template v-slot:prepend>
      <v-app-bar-nav-icon :icon='drawerIcon' color=secondary v-on:click="emitToggle"></v-app-bar-nav-icon>
    </template> -->


    <!--
    <template v-slot:prepend>
      <v-container class='mx-auto d-flex align-center justify-center'>
        <v-app-bar-title> {{ $route.meta.title }}</v-app-bar-title>!-- @click="drawer = !drawer" --
      </v-container>
    </template>
-->

    <template v-slot:append>

      <v-menu offset-y v-if="identityStore.isAdministrator">
        <template v-slot:activator="{ props }">
          <v-btn color="black" v-bind="props" class="mr-6">BACKOFFICE</v-btn>
        </template>

        <v-list>
          <v-list-item :to="{ name: 'scanner'}" prepend-icon="$qrCodeScan" base-color="purple">
            <v-list-item-title>Scanner</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'backoffice-orders'}" prepend-icon="$walletMembership" base-color="orange">
            <v-list-item-title>Ordini</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'backoffice-memberships'}" prepend-icon="$walletMembership" base-color="indigo">
            <v-list-item-title>Tessere</v-list-item-title>
          </v-list-item>

          <v-list-item href='/scheduler' prepend-icon="$walletMembership" base-color="blue">
            <v-list-item-title>Scheduler</v-list-item-title>
          </v-list-item>

          <v-list-item href='http://bml.alfero.net:8898/login?next=/browser/' prepend-icon="$database" base-color="red">
            <v-list-item-title>Database</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn v-if="identityStore.isAuthenticated" color="secondary" append-icon="$logout" class="text-uppercase" rounded
             v-on:click="onLogout"
             :loading="$api.isLoading"> {{ identityStore.displayName }}
      </v-btn>

    </template>

  </v-card>
</template>

<script setup lang="ts">


</script>

<script lang='ts'>

import { useUserStore } from '@/store/identityStore';
import { useCartStore } from '@/store/cartStorage';

export default {
  props: {
    propDrawer: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    drawerIcon() {
      return '$' + (this.propDrawer ? 'chevron-left' : 'chevron-right');
    }
  },
  data() {
    return {
      elevation: 0,
      identityStore: useUserStore(),
    }
  },
  methods: {
    handleScroll() {
      const scrollY = window.scrollY;
      const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPercentage = scrollY / maxScroll;
      this.elevation = Math.min(Math.max(scrollPercentage, 0), 1);
      this.elevation = this.elevation > 0.2 ? 1 : 0;
    },
    emitToggle(e) {
      //this.drawer = !this.propDrawer;
      this.$emit('update-data', !this.propDrawer);
    },
    async onLogout() {

      const cartStore = useCartStore();
      cartStore.clearCart();

      this.$router.push({ name: 'home' });
      await this.identityStore.logout();
    }
  },
  created() {
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style scoped>
.translucent {
  background-color: rgba(255, 255, 255, 0.5) !important;
  backdrop-filter: blur(11px) grayscale(30%);
}


.text-uppercase {
  text-transform: uppercase;
  font-size: .8em;
  color: #444
}
</style>

